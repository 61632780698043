import {
  Place,
  getPunctuatedAddressFromTags,
  placeFromMatchedAddress,
  SmartyStreetsLookupResponse
} from '@adg/catalog/src/common/addressUtil'
import { AuthorizeTokenResponse } from '@adg/catalog/src/modules/Apis/authorizeToken'
import { newStoreOrder, StoreOrder } from '@adg/catalog/src/modules/Order'
import {
  InstallOption,
  newSchedule,
  OrderSchedule,
  Promo,
  newPackage,
  Catalog,
  CatalogAddress,
  Package,
  Upgrade,
  Equipment,
  ConfigKeys
} from '@adg/catalog/src/modules/Catalog'
import { GA4EventWrapper, GtmEvent } from '@adg/catalog/src/modules/Ecom/gtmEvents'
import {
  newShopper,
  Referral,
  SecurityQuestion,
  shopperDelta,
  IShopper,
  SalesType,
  ServiceabilityState,
  Validation,
  SavedState,
  QualityScoreStatus
} from '@adg/catalog/src/modules/Shopper'
import { CreditCheckData, HubSpotLeadCaptureData, TimeSlotData, SubmitOrderData } from '@adg/catalog/src/modules/Apis'
import { parsePhoneNumber } from '@adg/catalog/src/common/PhoneNumber'
import { PaymentInfo } from '@adg/catalog/src/modules/Converge/converge'
import { getStateToSave, Spinner } from './../State'
import { ConfigItems, Product, ShoppingOrder } from '@adg/catalog/src/modules/Catalog/'
import { allConfigItems, getConfigBoolean, getConfigString } from './../../components/shared/getConfigItem'
import { httpClient, makeAxiosConfig, makeIdempotentRetryRequest } from '../http-client'
import axios, { AxiosRequestConfig } from 'axios'
import ecom from '@/gtm/ecom'
import { MatchType } from '@adg/catalog/src/common/MatchType'
import { EmailInfo } from '@adg/catalog/src/common/EmailInfo'
import { v4 as uuidv4 } from 'uuid'

import useServiceability from '@/components/serviceability/useServiceability'

import {
  FETCH_ADDRESS_SUGGESTIONS,
  FETCH_PREVIOUS_ADDRESS_SUGGESTIONS,
  FETCH_BILLING_ADDRESS_SUGGESTIONS,
  FETCH_CATALOG,
  GET_ADDRESS_SUGGESTIONS,
  GET_PREVIOUS_ADDRESS_SUGGESTIONS,
  GET_BILLING_ADDRESS_SUGGESTIONS,
  GET_ADDRESS_VERIFIED,
  GET_CATALOG,
  GET_CURRENT_STEP,
  GET_MATCHED_ADDRESS,
  GET_PRODUCTS,
  GET_SALES_LEAD,
  GET_TV_EQUIPMENT,
  RESET_ADDRESS_SUGGESTIONS,
  RESET_PREVIOUS_ADDRESS_SUGGESTIONS,
  RESET_SERVICEABILITY,
  SET_ADDRESS_SUGGESTIONS,
  SET_PREVIOUS_ADDRESS_SUGGESTIONS,
  SET_BILLING_ADDRESS_SUGGESTIONS,
  SET_ADDRESS_VERIFIED,
  SET_CATALOG,
  SET_CURRENT_STEP,
  SET_SHOW_SPINNER,
  SET_MATCHED_ADDRESS,
  SET_PACKAGE,
  SET_SALES_LEAD,
  UPDATE_SHOPPER_INFO,
  SET_SHOPPER,
  SET_ORDER,
  SET_TV_EQUIPMENT,
  UPGRADE_PACKAGE,
  GET_SHOW_SPINNER,
  SUBCATEGORY,
  SET_REFERRER,
  GET_REFERRER,
  SET_ID_IMAGE,
  SET_UNIQUE_PLAN_ID
} from '@/store/types'
import { SpinnerConfig } from '@/components/SpinnerConfig'
import { cloneDeep, flatten } from 'lodash'
import PackageUpgrade from '@/interfaces/PackageUpgrade'
import { getConfigItem } from '@/components/shared/getConfigItem'
import { rot13Cipher } from '@adg/catalog/src/util/rot13Cipher'
import uuencode from 'uuencode'
import { SalesLeadInfo } from '@adg/catalog/src/modules/Catalog'
import { bus } from '@/main'
import { newCart } from '@adg/catalog/src/modules/Order'
import { State } from '../State'
import { RemoteLoggingMessage } from '@adg/catalog/src/common/RemoteLoggingMessage'
import { LegacyAutomationState, AutomationInfo, goodStatus } from '@adg/catalog/src/common/AutomationState'
import { FullAddress } from '@adg/catalog/src/modules/Address/address'
import { logger } from '@/utils/RemoteLogger'
import { AccountPanel, newUIConfig, PaymentConfig, SchedulingConfig, ApiConfig, UIConfig } from '@adg/catalog/src/common/UIConfig'
import { usePiniaShopper } from '../pinia/piniaShopper'
import { usePiniaUIConfig } from '../pinia/piniaUIConfig'
import { isCompatibleShopper, setShopperElement } from '@/utils/ShopperHelpers'
import { CreditCheckResponse, CreditCheckType } from '@adg/catalog/src/common/CreditChecks'
import { AutomationSchedule, Timeslot, UpdatesFromIntegration } from '@adg/catalog/src/common/AutomationSchedule'
import { Note } from '@adg/catalog/src/modules/Ticket/Note'
import ga4 from '@/gtm/ga4'
import { getSalesLeadInfo } from '@/utils/SalesLeadHelpers'
import { getElement, UrlParams } from '@adg/catalog/src/common/utils'
import { Store } from 'vuex'
import {
  ADGPaymentFinalizer,
  ADGPaymentFinalizerRequest,
  ADGPaymentInitializerRequest,
  ADGPaymentInitializeData,
  ADGPaymentInitializerResponse,
  PaymentDisposition
} from '@adg/catalog/src/common/Payments'
import { FetchCatalogRequest, FetchCatalogResult } from '@adg/catalog/src/modules/Services/FetchCatalog'
import { assertNotNull } from '@adg/catalog/src/util/tsHelpers'
import { appNewShopper } from '@/utils/StoreHelpers'
import { CreateBroadbandLabelPayload, UpdateBroadbandLabelPayload } from '@/components/order/package/useBroadbandLabel'

declare var grecaptcha: any

export const GET_TV_UPGRADES = 'GET_TV_UPGRADES'
export const GET_ALL_PRODUCT_ITEMS = 'GET_ALL_PRODUCT_ITEMS'

//const rot13Cipher = (str: string) => str.replace(/[a-z]/gi, (x: string) => String.fromCharCode(x.charCodeAt(0) + (x.toLowerCase() <= 'm' ? 13 : -13)))

export interface AccountFieldSetter {
  field: string
  value: any
}

export const intialCartData = (): ShoppingOrder => {
  return {
    id: null,
    items: [],
    package: { Name: '', itemType: 'Package', 'Product Types': [] },
    products: [],
    monthlyCharges: [],
    oneTimeCharges: [],
    monthlyTotal: '',
    grandTotal: ''
  }
}

// export const initialOrder = (): StoreOrder => {
//   return {
//     id: null,
//     cartData: intialCartData()
//     //feeGroups: []
//   }
// }

export const initialServiceablity = (): ServiceabilityState => {
  return {
    addressSuggestions: [],
    previousAddressSuggestions: [],
    billingAddressSuggestions: [],
    addressVerified: undefined,
    matchedAddress: undefined,
    salesLead: undefined
  }
}

const store: State = {
  currentStep: 1,
  shopper: newShopper(),
  cart: newCart(),
  cartItems: [],
  retrievedShopper: newShopper(),
  order: newStoreOrder(),
  serviceability: initialServiceablity(),
  availablePackageUpgrades: [],
  schedule: null,
  scheduleOptions: [],
  config: newUIConfig(),
  catalog: undefined,
  validation: {
    accountInfo: false,
    phoneInfo: false
  },
  mobileCart: false,
  showBanner: false,
  showSpinner: { show: false },
  spinners: [] as Spinner[],
  spinnerUpdateTime: 10,
  gaEvents: [],
  incomingLob: undefined,
  referrer: undefined,
  UrlParams: undefined,
  configItems: {} as ConfigItems,
  debugMode: false,
  selectedProductTypes: [],
  idImage: '',
  shopperMetricSent: false,
  paymentInitializer: null,
  labelsGenerated: []
}

const getters = {
  getState: (state: State) => {
    return state
  },
  getCurrentState: (state: State) => {
    return { ...state, catalog: null }
  },
  getSelectedProductTypes: (state: State) => state.selectedProductTypes,
  getSpinners: (state: State) => state.spinners,
  getSpinnerUpdateTime: (state: State) => state.spinnerUpdateTime,
  getPaymentInfo: (state: State) => state.shopper.customInfo?.paymentInfo || '',
  getScheduleOptions: (state: State) => state.scheduleOptions,
  getAvailableSchedule: (state: State) => state.schedule,
  getIncomingLob: (state: State) => {
    return state.UrlParams?.lobs
  },
  allowDebug: (state: State) => {
    const env = getConfigString(ConfigKeys.env)
    const prdPassword = getConfigItem(ConfigKeys.debugPassword)

    if (prdPassword === undefined && env !== 'prd' && env !== 'uat') {
      return state.UrlParams?.debug
    }
    if (typeof prdPassword === 'string') {
      return state.UrlParams?.debug === prdPassword
    }
    if (Array.isArray(prdPassword)) {
      return flatten(prdPassword).some((password) => password === state.UrlParams?.debug)
    }
    return false
  },
  getDebugMode: (state: State) => state.debugMode,
  getAvailablePackageUpgrades: (state: State) => state.availablePackageUpgrades,
  [GET_CURRENT_STEP]: (state: State) => state.currentStep,
  [GET_MATCHED_ADDRESS]: (state: State): FullAddress | undefined => state.serviceability.matchedAddress,
  [GET_ADDRESS_VERIFIED]: (state: State) => state.serviceability.addressVerified,
  [GET_ADDRESS_SUGGESTIONS]: (state: State): Place[] => state.serviceability.addressSuggestions,
  [GET_PREVIOUS_ADDRESS_SUGGESTIONS]: (state: State) => state.serviceability.previousAddressSuggestions,
  [GET_BILLING_ADDRESS_SUGGESTIONS]: (state: State) => state.serviceability.billingAddressSuggestions,
  [GET_SHOW_SPINNER]: (state: State) => state.showSpinner,
  getMobileCart: (state: State) => state.mobileCart,
  getShowBanner: (state: State) => state.showBanner,
  getNumTv: (state: State): number => state.shopper.cart.tv.numTv,
  getCart: (state: State) => state.shopper.cart,
  getOrder: (state: State) => state.order,
  getShopper: (state: State): IShopper => state.shopper,
  // getStateToSubmit: (state: State): Partial<State> => {
  //   return state
  // },
  getAccountField: (state: State) => (field: string) => {
    return getElement(state.shopper, field) ?? undefined
  },
  getAccountPanels: (state: State) => {
    return state.configItems.accountPanels
  },
  getFullyAutomated: (state: State) => {
    return state.shopper.fullyAutomated
  },
  getConfigItems: (state: State) => {
    return state.configItems
  },
  getStudent: (state: State) => state.shopper.customInfo?.isStudent || false,
  getIdImage: (state: State) => state.shopper.idImage || '',
  getFormattedAddress: (state: State) => {
    return state.shopper.formattedAddress ? state.shopper.formattedAddress : ''
  },
  // getPunctuatedAddress: (state: State) => {
  //   const pAdd = state.shopper.matchedAddress?.inputAddress
  //   // console.log(pAdd)
  //   return state.shopper.punctuatedAddress
  //     ? getPunctuatedAddressFromTags(state.shopper)
  //     : state.shopper.tags?.punctuatedAddress
  //     ? state.shopper.tags.punctuatedAddress
  //     : state.shopper.matchedAddress?.inputAddress
  //     ? state.shopper.matchedAddress?.inputAddress
  //     : ''
  // },
  [GET_CATALOG]: (state: State) => state.catalog,
  getCatalogConfig: (state: State) => (state.catalog ? state.catalog.Config : undefined),
  getMarketingText: (state: State) => (state.catalog ? state.catalog['MarketingText'] : []),
  // getPackage: (state) => (state.shopper.cart.package ? state.shopper.cart.package : {}),
  getPackage: (state: State) => {
    return state?.shopper?.cart?.package ?? newPackage()
  },
  [GET_TV_UPGRADES]: (state: State) => state.shopper.cart.tv.upgrades,
  [GET_TV_EQUIPMENT]: (state: State) => state.shopper.cart.tv.equipment,
  getInstallOption: (state: State) => state.shopper.cart.schedule?.installOption,
  getAccountInfoValid: (state: State) => state.validation.accountInfo,
  getPhoneInfoValid: (state: State) => state.validation.phoneInfo,
  getCartSchedule: (state: State): OrderSchedule => state.shopper.cart.schedule,
  getPackageUpgrades: (state: State) => state.shopper.cart.packageUpgrades,
  getPromo: (state: State) => state.shopper.cart.promo,
  getUIConfig: (state: State) => state.config,
  [GET_SALES_LEAD]: (state: State): SalesLeadInfo | undefined => state.serviceability.salesLead,
  [GET_PRODUCTS]: (state: State) => state.shopper.cart.products,
  getPackageProducts: (state: State): Product[] => state.shopper.cart.package?.Products ?? [],
  getPhone: (state: State) => state.shopper.cart.phone,
  getPhoneEquipment: (state: State) => state.shopper.cart.phone.equipment,
  getInternetUpgrades: (state: State) => state.shopper.cart.internet.upgrades,
  getFeatures: (state: State) => state.catalog?.features,
  getLabelsGenerated: (state: State) => state.labelsGenerated,
  getComingSoon(state: State): boolean {
    const comingSoon = getConfigItem(ConfigKeys.comingSoon)
    if (!comingSoon?.tag || !comingSoon?.tagVal) return false
    return state.shopper.tags?.[comingSoon.tag] === comingSoon.tagVal
  },
  [GET_REFERRER]: (state: State) => state.referrer,
  [GET_ALL_PRODUCT_ITEMS]: (state: State) => {
    const all = flatten(
      state.shopper.cart.products.map((product) => {
        const upgrades = product.Upgrades ? product.Upgrades : []
        const equipment = product.Equipment ? product.Equipment : []
        return [...upgrades, ...equipment]
      })
    )
    return all
  },
  getUrlParams: (state: State) => state.UrlParams,
  getLatestCreditCheck: (state: State): CreditCheckResponse => {
    const creditChecks = state.shopper.customInfo.creditChecks
    const latest = creditChecks.length > 0 ? creditChecks[creditChecks.length - 1] : undefined
    return latest?.softCreditCheck
  },
  getFirstCreditCheck: (state: State): CreditCheckResponse => {
    const creditChecks = state.shopper.customInfo.creditChecks
    const first = creditChecks.length > 0 ? creditChecks[0] : undefined
    return first?.softCreditCheck
  },
  getShopperMetricSent: (state: State) => {
    return state.shopperMetricSent
  },
  getPaymentInitializer: (state: State) => {
    return state.paymentInitializer
  },
  getAppLoadId: (state: State) => {
    return state.shopper.appLoadId
  },
  getBuyflowStartId: (state: State) => {
    return state.shopper.buyflowStartId
  }
}

const mutations = {
  addGeneratedLabel(state: State, label: any) {
    state.labelsGenerated?.push(label)
  },
  setState(state: any, newState: any) {
    state = newState
  },
  setCartItems(state: { cartItems: any }, items: any) {
    state.cartItems = items
  },
  setDebugMode(state: { debugMode: any }, debugMode: any) {
    state.debugMode = debugMode
  },
  setTextArea(state: { shopper: { customInfo: { textArea: any } } }, text: any) {
    state.shopper.customInfo.textArea = text //piniaComplete
  },
  setAccountPanels(state: { configItems: { accountPanels: AccountPanel[] } }, accountPanels: AccountPanel[]) {
    state.configItems.accountPanels = accountPanels
  },
  setSelectedProductTypes(state: { selectedProductTypes: any }, productTypes: any) {
    state.selectedProductTypes = productTypes
  },
  setAccountField(state: { shopper: object }, { field, value }: AccountFieldSetter) {
    // this.commit('setElement', { dest: state.shopper, field, value })
    // if (typeof value === 'boolean') {
    //   eval(`state.shopper.${field} = ${value}`)
    // } else {
    //   eval(`state.shopper.${field} = '${value}'`)
    // }
    if (typeof value === 'string') {
      value = value.trim()
    }
    setShopperElement(state.shopper, field, value)
  },
  setTermsCheckbox(state: { shopper: { customInfo: { termsConsent: any } } }, values: any) {
    state.shopper.customInfo.termsConsent = values //piniaComplete
  },
  setPhonePortConsent(state: { shopper: { customInfo: { phonePortConsent: any } } }, values: any) {
    state.shopper.customInfo.phonePortConsent = values //piniaComplete
  },
  setDob(state: { shopper: { customInfo: { dob: string; dobString: any } } }, dob: string | number | Date) {
    state.shopper.customInfo.dob = new Date(dob).toISOString() //piniaComplete
    state.shopper.customInfo.dobString = dob
  },
  // addSpinner(state: State, spinnerMsg: string) {
  //   state.spinners.push(spinnerMsg)
  // },
  // removeSpinner(state: State, spinnerMsg: string) {
  //   state.spinners.splice(state.spinners.indexOf(spinnerMsg), 1) //doesn't handle out of order processing, but should be ok till we run into that issue
  // },
  addSpinner(state: State, spinner: Spinner) {
    state.spinners = [...state.spinners, spinner]
  },
  removeSpinner(state: State, id: string) {
    state.spinners = state.spinners.filter((s) => s.id !== id)
    //state.spinners.splice(state.spinners.indexOf(spinnerMsg), 1) //doesn't handle out of order processing, but should be ok till we run into that issue
  },
  setSpinners(state: State, spinners: Spinner[]) {
    state.spinners = spinners
  },
  setSpinnerUpdateTime(state: State, updateTime: number) {
    state.spinnerUpdateTime = updateTime
  },
  addCreditCheck(state: State, creditCheck: { softCreditCheck: { creditCheckResult: string } }) {
    if (!state.shopper.customInfo.creditChecks) {
      state.shopper.customInfo.creditChecks = []
    }
    state.shopper.customInfo.creditChecks.push(creditCheck)
    state.shopper.customInfo.creditCheckPassed = creditCheck?.softCreditCheck?.creditCheckResult === 'approved' //piniaComplete
  },
  setPreSale(state: State, preSale: boolean) {
    state.shopper.customInfo.preSale = preSale //piniaComplete
    if (state.shopper.matchedAddress === undefined) {
      state.shopper.matchedAddress = {}
    }
    state.shopper.matchedAddress.salesType = preSale
      ? 'presale'
      : state.shopper.matchedAddress?.serviceable === 'Y'
      ? 'serviceable'
      : 'none'
  },
  setInitialPreSale(state: State, initialPreSale: boolean | undefined) {
    state.shopper.customInfo.initialPreSale = initialPreSale //piniaComplete
  },
  setReturningPreSale(state: State, returningPreSale: boolean | undefined) {
    state.shopper.customInfo.returningPreSale = returningPreSale //piniaComplete
  },
  setSelfInstall(state: State, selfInstall: boolean | undefined) {
    state.shopper.customInfo.canSelfInstall = selfInstall //piniaComplete
  },
  setPaymentInfo(state: State, paymentInfo: PaymentInfo) {
    state.shopper.customInfo.paymentInfo = paymentInfo //piniaComplete
  },
  setPaymentDisposition(state: State, disposition: PaymentDisposition) {
    state.shopper.customInfo.paymentDisposition = disposition
  },
  setScheduleOptions(state: State, options: Timeslot[][] | undefined) {
    state.scheduleOptions = options
  },
  setLeadFormName(state: State, name: string) {
    state.shopper.leadFormName = name
  },
  setAvailableSchedule(state: State, schedule: OrderSchedule | undefined | null) {
    if (schedule === undefined) {
      schedule = null
    }
    state.schedule = schedule
  },
  setPaymentInitializer(state: State, initializer: ADGPaymentInitializerResponse) {
    state.paymentInitializer = initializer
  },
  [SET_REFERRER](state: State, referer: string | undefined) {
    state.referrer = referer
  },
  setAvailablePackageUpgrades(state: State, upgrades: Upgrade[]) {
    state.availablePackageUpgrades = upgrades
  },
  setSsn(state: State, ssn: string | undefined) {
    state.shopper.customInfo.ssn = ssn //piniaComplete
  },
  setExternalAccountNumber(state: State, accountNum: string) {
    state.shopper.externalAccountNumber = accountNum //piniaComplete
  },
  setReadyDate(state: State, readyDate: string | undefined) {
    state.shopper.customInfo.readyDate = readyDate //piniaComplete
  },
  //todo: why is this red when it is already in the CustomInfo type definition?
  setReoccuringPayments(state: State, reoccuringPayments: boolean | undefined) {
    state.shopper.customInfo.reoccuringPayments = reoccuringPayments
  },
  setSalesType(state: State, salesType: SalesType) {
    state.shopper.salesType = salesType
  },
  [SET_ID_IMAGE](state: State, image: string | undefined) {
    state.shopper.idImage = image //TODO: make piniaComplete
  },
  [SET_CURRENT_STEP](state: State, currentStep: number) {
    state.currentStep = currentStep
  },
  [SET_SHOW_SPINNER](state: State, showSpinner: SpinnerConfig) {
    state.showSpinner = showSpinner
  },
  [SET_SALES_LEAD](state: State, salesLead: SalesLeadInfo | undefined) {
    state.serviceability.salesLead = salesLead
  },
  [RESET_SERVICEABILITY](state: State) {
    state.serviceability = initialServiceablity()
  },
  [UPDATE_SHOPPER_INFO](state: State, shopperInfo: Partial<IShopper>) {
    state.shopper = { ...state.shopper, ...shopperInfo } // better way
    // Object.keys(shopperInfo).forEach((key) => (state.shopper[key] = shopperInfo[key])) //piniaComplete
  },
  [SET_SHOPPER](state: State, shopperInfo: IShopper) {
    state.shopper = shopperInfo //piniaComplete
  },
  [SET_ADDRESS_VERIFIED](state: State, verified: boolean) {
    state.serviceability.addressVerified = verified
  },
  [RESET_ADDRESS_SUGGESTIONS](state: State, suggestions: Place) {
    state.serviceability.addressSuggestions = []
  },
  [SET_ADDRESS_SUGGESTIONS](state: State, suggestions: Place[]) {
    state.serviceability.addressSuggestions = suggestions
  },
  [RESET_PREVIOUS_ADDRESS_SUGGESTIONS](state: State) {
    state.serviceability.previousAddressSuggestions = []
  },
  [SET_PREVIOUS_ADDRESS_SUGGESTIONS](state: State, suggestions: Place[]) {
    state.serviceability.previousAddressSuggestions = suggestions
  },
  [SET_BILLING_ADDRESS_SUGGESTIONS](state: State, suggestions: Place[]) {
    state.serviceability.billingAddressSuggestions = suggestions
  },

  [SET_TV_EQUIPMENT](state: State, equipment: Equipment[]) {
    const orig = [...state.shopper.cart.tv.equipment]
    state.shopper.cart.tv.equipment = equipment
    bus.$emit('cartChanged')
    ecom.pushMultiItemChange(orig, equipment)
    ga4.pushMultiItemChange(orig, equipment)
  },
  [SET_ORDER](state: State, order: StoreOrder) {
    state.order = order
  },
  setOrderId(state: State, id: any) {
    const idNum = Number(id)
    if (!isNaN(idNum)) {
      state.order.id = idNum
      state.shopper.orderNumber = idNum
      usePiniaShopper().shopper.orderNumber = idNum
    }
  },
  resetOrder(state: State) {
    //console.log('reset order')
    state.order = newStoreOrder()
    state.serviceability = initialServiceablity()

    this.commit('initializeShopper', state)
    state.currentStep = 1
    state.catalog = undefined
    bus.$emit('cartChanged')
  },
  initializeShopper(state: State) {
    const qualityScore = state.shopper.qualityScore
    const qualityScoreTime = state.shopper.qualityScoreTime
    const qualityScoreStatus = state.shopper.qualityScoreStatus
    state.shopper = appNewShopper()
    state.shopper.qualityScore = qualityScore
    state.shopper.qualityScoreTime = qualityScoreTime
    state.shopper.qualityScoreStatus = qualityScoreStatus
    this.commit('setUrlParams', state.UrlParams)
  },
  resetCart(state: State) {
    // console.log('reset cart')
    state.order = newStoreOrder()
    state.shopper.cart = newCart() //any problem with adding this line?
    // console.log('new cart', state.shopper.cart)

    bus.$emit('cartChanged')
  },
  setMobileCart(state: State, show: boolean) {
    state.mobileCart = show
  },
  setCartSchedule(state: State, schedule: OrderSchedule) {
    state.shopper.cart.schedule = schedule
  },
  setEmailScheduleInfo(state: State, info: string | undefined) {
    if (state.shopper.cart.schedule) {
      state.shopper.cart.schedule.emailScheduleInfo = info
    }
  },
  setStudent(state: State, isStudent: boolean | undefined) {
    state.shopper.customInfo.isStudent = isStudent //piniaComplete
  },
  setShopperFirstName(state: State, fname: string) {
    state.shopper.firstName = fname //piniaComplete
  },
  setShopperLastName(state: State, lname: string) {
    state.shopper.lastName = lname //piniaComplete
  },
  setShopperEmail(state: State, email: string) {
    state.shopper.email = email //piniaComplete
  },
  setShopperPhone(state: State, phoneNumber: string) {
    state.shopper.phone = phoneNumber //piniaComplete
  },
  setShopperAutomationState(state: State, automation: LegacyAutomationState) {
    state.shopper.automationState = automation
  },
  setShopperOrder(state: State, shoppingCart: ShoppingOrder) {
    state.shopper.order = shoppingCart
    state.order.cartData = shoppingCart
  },
  sendShopperMetric(state: State) {
    // only send once per app load
    if (!state.shopperMetricSent) {
      ga4.pushShopperMetric()
      state.shopperMetricSent = true
    }
  },
  // Only update those in state that match subcategory, everything else is left.
  //TODO: Use this pattern for rest of cart to possibly simplify?
  setPackageUpgrades(state: State, payload: PackageUpgrade) {
    const orig = [...state.shopper.cart.packageUpgrades]
    const remain = state.shopper.cart.packageUpgrades.filter((p) => payload.subCategory !== p[SUBCATEGORY])
    const newUpgrades = [...remain, ...payload.upgrades]
    state.shopper.cart.packageUpgrades = newUpgrades
    bus.$emit('cartChanged')
    ecom.pushMultiItemChange(orig, newUpgrades)
    ga4.pushMultiItemChange(orig, newUpgrades)
  },
  setSecurityQuestions(state: State, payload: SecurityQuestion[]) {
    state.shopper.customInfo.securityQuestions = payload
  },
  setReferralQuestions(state: State, payload: Referral[]) {
    state.shopper.customInfo.referrals = payload
  },
  setNumTv(state: State, payload: number) {
    state.shopper.cart.tv.numTv = payload
  },

  [SET_CATALOG](state: State, payload: Catalog) {
    const catalog = payload
    state.catalog = catalog
    // if (catalog && catalog.RefData['Fee Groups']) {
    //   state.order.feeGroups = catalog.RefData['Fee Groups']
    // }
  },
  [SET_UNIQUE_PLAN_ID](state: State, payload: string) {
    state.shopper.uniquePlanId = payload
  },
  [UPGRADE_PACKAGE](state: State, payload: Package) {
    const origPkg = state.shopper.cart.package
    state.shopper.cart.package = payload
    ecom.pushPackageChange(origPkg ? { ...origPkg } : null, { ...payload })
    ga4.pushPackageChange(origPkg ? { ...origPkg } : null, { ...payload })
  },

  [SET_PACKAGE](state: State, payload: Package) {
    try {
      state.shopper.cart.tv.upgrades = []

      const origPkg = { ...state.shopper.cart.package }
      ecom.pushPackageChange(origPkg ? { ...origPkg } : null, { ...payload })
      ga4.pushPackageChange(origPkg ? { ...origPkg } : null, { ...payload })
      this.commit('resetCart', state)
      state.shopper.cart.package = payload
      state.shopper.cart.products = payload.Products.map((p) => ({ ...p }))
      const packageServices =
        payload.Upgrades?.filter((u) => u.Subcategory === 'Package Service' || u.Subcategory === 'Streaming Service') ?? []
      if (packageServices) {
        let defaultUpgrades = packageServices.filter((u: any) => u.Default === 'yes' && !u.required && !u.included && !u.excluded)
        const excludedUpgrades = packageServices.filter((u) => {
          return u.excluded
        })
        const requiredUpgrades = packageServices.filter((u: any) => u.required && !u.excluded)
        const includedUpgrades = packageServices.filter((u) => u.included === '1' && u.excluded !== '1')
        // .map(
        //   (u) =>
        //     ({
        //       ...u,
        //       'Monthly Price': 'Included'
        //     } as Upgrade)
        // )
        let upgrades: Upgrade[] = state.shopper.cart.packageUpgrades
        upgrades = upgrades.filter((upgrade) => packageServices?.find((e) => e.Name === upgrade.Name))
        if (includedUpgrades.length) {
          upgrades = upgrades.filter((upgrade) => {
            if (includedUpgrades.find((e) => e.Name === upgrade.Name)) return false
            if (includedUpgrades.find((e) => e.Group === upgrade.Group && e.Min === 1)) return false
            return true
          })

          upgrades = [...upgrades, ...includedUpgrades]
        }
        if (requiredUpgrades.length) {
          upgrades = upgrades.filter((upgrade) => {
            if (requiredUpgrades.find((e) => e.Name === upgrade.Name)) return false
            if (requiredUpgrades.find((e) => e.Group === upgrade.Group && e.Min === 1)) return false
            return true
          })
          upgrades = [...upgrades, ...requiredUpgrades]
        }
        if (defaultUpgrades.length) {
          upgrades = upgrades.filter((upgrade) => !defaultUpgrades.find((e) => e.Name === upgrade.Name))
          //in the case of groups with defaults, we want to ignore the defaults when there are already upgrades selected
          defaultUpgrades = defaultUpgrades.filter((df) => !upgrades.find((up) => up.Group === df.Group && up.Min === 1))
          upgrades = [...upgrades, ...defaultUpgrades]
        }
        if (excludedUpgrades.length) {
          upgrades = upgrades.filter((upgrade) => !excludedUpgrades.find((e) => e.Name === upgrade.Name))
        }
        //state.shopper.cart.packageUpgrades = upgrades
        //hopefully fix ga4 package upgrades
        this.commit('setPackageUpgrades', {
          upgrades: upgrades,
          subCategory: 'Package Service'
        })
      }
      // state.shopper.cart.packageUpgrades = []
      state.availablePackageUpgrades = payload.Upgrades || []

      //TODO: handle payload.Upgrades for excluded, reguired and included

      const tv = payload.Products.find((p) => p['Product Type'] === 'TV')
      if (tv) {
        const upgradesAndEquipment = (tv.Equipment ?? []).concat(tv.Upgrades ?? [])

        let defaultEquipment =
          upgradesAndEquipment?.filter(
            (u) => u.Default === 'yes' && u.Max === 1 && u.required !== '1' && u.included !== '1' && u.excluded !== '1'
          ) || []
        const tvRequiredEquipment = upgradesAndEquipment?.filter((u) => u.required === '1' && u.excluded !== '1')
        const tvIncludedEquipment = upgradesAndEquipment?.filter((u) => u.included === '1' && u.excluded !== '1')
        // .map(
        //   (u) =>
        //     ({
        //       ...u,
        //       'Monthly Price': 'Included'
        //     } as Equipment)
        // )
        const tvExcludedEquipment = upgradesAndEquipment?.filter((u) => u.excluded === '1')

        let upgrades: (Equipment | Upgrade)[] = state.shopper.cart.tv.upgrades
        // remove equipment that doesn't exist on new package
        upgrades = upgrades.filter((upgrade) => upgradesAndEquipment?.find((e) => e.Name === upgrade.Name))

        if (tvIncludedEquipment.length) {
          //filter duplicate equipment
          upgrades = upgrades.filter((upgrade) => {
            if (tvIncludedEquipment.find((e) => e.Name === upgrade.Name)) return false
            if (tvIncludedEquipment.find((e) => e.Group === upgrade.Group && e.Min === 1)) return false
            return true
          })

          upgrades = [...upgrades, ...tvIncludedEquipment]
        }
        if (tvRequiredEquipment.length) {
          //filter duplicate requireds
          upgrades = upgrades.filter((upgrade) => {
            if (tvRequiredEquipment.find((e) => e.Name === upgrade.Name)) return false
            if (tvRequiredEquipment.find((e) => e.Group === upgrade.Group && e.Min === 1)) return false
            return true
          })
          upgrades = [...upgrades, ...tvRequiredEquipment]
        }
        if (defaultEquipment.length) {
          //filter duplicate defaults
          upgrades = upgrades.filter((upgrade) => !defaultEquipment.find((e) => e.Name === upgrade.Name))
          //in the case of groups with defaults, we want to ignore the defaults when there are already upgrades selected
          defaultEquipment = defaultEquipment.filter((df) => !upgrades.find((up) => up.Group === df.Group && up.Min === 1))
          upgrades = [...upgrades, ...defaultEquipment]
        }
        if (tvExcludedEquipment.length) {
          upgrades = upgrades.filter((upgrade) => !tvExcludedEquipment.find((e) => e.Name === upgrade.Name))
        }
        // state.shopper.cart.tv.upgrades = upgrades
        // hopefully fix ga4 bug
        this.commit('setTvUpgrades', upgrades)

        // const included =
        //   tv.Upgrades?.filter((u) => u.included === '1').map(
        //     (u) =>
        //       ({
        //         ...u,
        //         'Monthly Price': 'Included'
        //       } as Upgrade)
        //   ) || []

        // state.shopper.cart.tv.numTv = 0
        // // let upgrades: Equipment[] = state.shopper.cart.tv.upgrades
        // // upgrades = upgrades.filter((upgrade) => tv.Upgrades?.find((e) => e.Name === upgrade.Name))

        // // if (included) {
        // //   upgrades = upgrades.filter((upgrade) => !included.find((e) => e.Name === upgrade.Name))
        // //   upgrades = [...upgrades, ...included]
        // // }

        // state.shopper.cart.tv.upgrades = included
      } else {
        state.shopper.cart.tv.upgrades = []
        state.shopper.cart.tv.equipment = []
        state.shopper.cart.tv.numTv = 0
      }
      const internet = payload.Products.find((p) => p['Product Type'] === 'Internet')
      if (internet) {
        const upgradesAndEquipment = (internet.Equipment ?? []).concat(internet.Upgrades ?? [])

        let defaultEquipment =
          upgradesAndEquipment?.filter(
            (u) => u.Default === 'yes' && u.required !== '1' && u.included !== '1' && u.excluded !== '1'
          ) || []
        const internetRequired = upgradesAndEquipment?.filter((u) => u.required === '1' && u.excluded !== '1')
        const internetIncluded = upgradesAndEquipment?.filter((u) => u.included === '1' && u.excluded !== '1')
        // this map section should not be needed anymore
        // .map(
        //   (u) =>
        //     ({
        //       ...u,
        //       'Monthly Price': 'Included'
        //     } as Equipment)
        // )
        const internetExcluded = upgradesAndEquipment?.filter((u) => u.excluded === '1')

        let upgrades: Equipment[] = state.shopper.cart.internet.upgrades
        // remove equipment that doesn't exist on new package
        upgrades = upgrades.filter((upgrade) => upgradesAndEquipment?.find((e) => e.Name === upgrade.Name))

        if (internetIncluded.length) {
          //filter duplicate equipment
          upgrades = upgrades.filter((upgrade) => {
            if (internetIncluded.find((e) => e.Name === upgrade.Name)) return false
            if (internetIncluded.find((e) => e.Group === upgrade.Group && e.Min === 1)) return false
            return true
          })

          upgrades = [...upgrades, ...internetIncluded]
        }
        if (internetRequired.length) {
          //filter duplicate requireds
          upgrades = upgrades.filter((upgrade) => {
            if (internetRequired.find((e) => e.Name === upgrade.Name)) return false
            if (internetRequired.find((e) => e.Group === upgrade.Group && e.Min === 1)) return false
            return true
          })
          upgrades = [...upgrades, ...internetRequired]
        }
        if (defaultEquipment.length) {
          //filter duplicate defaults
          upgrades = upgrades.filter((upgrade) => !defaultEquipment.find((e) => e.Name === upgrade.Name))
          //in the case of groups with defaults, we want to ignore the defaults when there are already upgrades selected
          defaultEquipment = defaultEquipment.filter(
            (df) => !upgrades.find((up) => up.Group === df.Group && up.Min === 1 && df.Min === 1)
          )
          upgrades = [...upgrades, ...defaultEquipment]
        }
        if (internetExcluded.length) {
          upgrades = upgrades.filter((upgrade) => !internetExcluded.find((e) => e.Name === upgrade.Name))
        }
        // state.shopper.cart.internet.upgrades = upgrades
        // hopefully fix ga4 events
        this.commit('setInternetUpgrades', upgrades)
      }

      const phone = payload.Products.find((p) => p['Product Type'] === 'Phone')
      if (phone) {
        const upgradesAndEquipment = (phone.Equipment ?? []).concat(phone.Upgrades ?? [])

        let defaultEquipment =
          upgradesAndEquipment?.filter(
            (u) => u.Default === 'yes' && u.required !== '1' && u.included !== '1' && u.excluded !== '1'
          ) || []
        const phoneRequired = upgradesAndEquipment?.filter((u) => u.required === '1' && u.excluded !== '1')
        const phoneIncluded = upgradesAndEquipment
          ?.filter((u) => u.included === '1' && u.excluded !== '1')
          .map(
            (u) =>
              ({
                ...u,
                'Monthly Price': 'Included'
              } as Equipment)
          )
        let upgrades: Equipment[] = state.shopper.cart.phone.equipment

        upgrades = upgrades.filter((upgrade) => upgradesAndEquipment?.find((e) => e.Name === upgrade.Name))
        if (phoneIncluded.length) {
          upgrades = upgrades.filter((upgrade) => {
            if (phoneIncluded.find((e) => e.Name === upgrade.Name)) return false
            if (phoneIncluded.find((e) => e.Group === upgrade.Group && e.Min === 1)) return false
            return true
          })

          upgrades = [...upgrades, ...phoneIncluded]
        }
        if (phoneRequired.length) {
          upgrades = upgrades.filter((upgrade) => {
            if (phoneRequired.find((e) => e.Name === upgrade.Name)) return false
            if (phoneRequired.find((e) => e.Group === upgrade.Group && e.Min === 1)) return false
            return true
          })
          upgrades = [...upgrades, ...phoneRequired]
        }
        if (defaultEquipment.length) {
          upgrades = upgrades.filter((upgrade) => !defaultEquipment.find((e) => e.Name === upgrade.Name))
          defaultEquipment = defaultEquipment.filter((df) => !upgrades.find((up) => up.Group === df.Group && up.Min === 1))

          upgrades = [...upgrades, ...defaultEquipment]
        }

        // state.shopper.cart.phone.equipment = upgrades
        // hopefully fix ga4 events
        this.commit('setPhoneEquipment', upgrades)
      }
      state.shopper.cart.promo = undefined
      state.showBanner = true
    } catch (e) {
      logger.error('Error updating package', e)
    }
  },
  updateShopperGoogleClientId(state: State, googleClientId: string) {
    state.shopper.googleClientId = googleClientId //piniaComplete
    //console.log(`googleClientId=${googleClientId}`)
  },

  //todo: adding googleSessionId and googleSessionNumber to the IShopper definition doesn't fix this and the below issue
  updateShopperGoogleSessionId(state: State, googleSessionId: string) {
    state.shopper.googleSessionId = googleSessionId
    //console.log(`googleSessionId=${googleSessionId}`)
  },

  updateShopperGoogleSessionNumber(state: State, googleSessionNumber: string) {
    state.shopper.googleSessionNumber = googleSessionNumber
    //console.log(`googleSessionNumber=${googleSessionNumber}`)
  },

  updateShopperPreviousAddress(state: State, newPlace: { full: string | undefined } | null) {
    if (newPlace != null) {
      state.shopper.customInfo.previousAddress = newPlace.full
      usePiniaShopper().shopper.customInfo.previousAddress = newPlace.full //piniaComplete KINDA
    } else {
      state.shopper.customInfo.previousAddress = undefined
      usePiniaShopper().shopper.customInfo.previousAddress = undefined
    }
  },
  updateShopperBillingAddress(state: State, newPlace: { full: string | undefined } | null) {
    if (newPlace != null) {
      state.shopper.customInfo.billingAddress = newPlace.full
      usePiniaShopper().shopper.customInfo.billingAddress = newPlace.full //piniaComplete KINDA
    } else {
      state.shopper.customInfo.billingAddress = undefined
      usePiniaShopper().shopper.customInfo.billingAddress = undefined
    }
  },
  setAcceptTextAlerts(state: State, accept: boolean | undefined) {
    state.shopper.customInfo.acceptTextAlerts = accept //piniaComplete
  },
  updateShopperAddress(state: State, addr: Place | undefined | null) {
    if (addr === undefined || addr === null) {
      state.shopper.place = undefined
      usePiniaShopper().shopper.place = undefined
      state.shopper.formattedAddress = undefined
      usePiniaShopper().shopper.formattedAddress = undefined
      state.shopper.punctuatedAddress = ''
      usePiniaShopper().shopper.punctuatedAddress = ''
    } else {
      state.shopper.place = addr
      usePiniaShopper().shopper.place = addr
      state.shopper.formattedAddress = addr.full
      usePiniaShopper().shopper.formattedAddress = addr.full
      if (addr.street_line && addr.city && addr.state && addr.zipcode) {
        const punctuatedAddress = getPunctuatedAddressFromTags(state.shopper)
        state.shopper.punctuatedAddress = punctuatedAddress
        usePiniaShopper().shopper.punctuatedAddress = punctuatedAddress
      } else {
        state.shopper.punctuatedAddress = addr.full
        usePiniaShopper().shopper.punctuatedAddress = addr.full
      }
    }
  },

  setCurrentPhoneNum(state: State, num: string | undefined) {
    state.shopper.customInfo.currentPhone ??= {}
    state.shopper.customInfo.currentPhone.number = num //piniaComplete
  },

  setTvUpgrades(state: State, upgrades: Upgrade[]) {
    // upgrades.forEach((u) => {
    //   if (u.priceIncluded) {
    //     u['Monthly Price'] = 'Included'
    //   }
    // })
    const orig = [...state.shopper.cart.tv.upgrades]
    state.shopper.cart.tv.upgrades = upgrades
    bus.$emit('cartChanged')
    ecom.pushMultiItemChange(orig, upgrades)
    ga4.pushMultiItemChange(orig, upgrades)
  },

  setInternetUpgrades(state: State, upgrades: Upgrade[]) {
    const orig = [...state.shopper.cart.internet.upgrades]

    // upgrades.forEach((u) => {
    //   if (u.included) {
    //     u['Monthly Price'] = 'Included'
    //   }
    // })

    state.shopper.cart.internet.upgrades = upgrades
    bus.$emit('cartChanged')
    ecom.pushMultiItemChange(orig, upgrades)
    ga4.pushMultiItemChange(orig, upgrades)
  },

  setPhoneUpgrades(state: State, upgrades: Upgrade[]) {
    const orig = [...state.shopper.cart.phone.upgrades]
    state.shopper.cart.phone.upgrades = upgrades
    bus.$emit('cartChanged')
    ecom.pushMultiItemChange(orig, upgrades)
    ga4.pushMultiItemChange(orig, upgrades)
  },
  setPhoneEquipment(state: State, equipment: Equipment[]) {
    const orig = [...state.shopper.cart.phone.equipment]
    state.shopper.cart.phone.equipment = equipment
    bus.$emit('cartChanged')
    ecom.pushMultiItemChange(orig, equipment)
    ga4.pushMultiItemChange(orig, equipment)
  },
  updateFullyAutomated(state: State, fullyAutomated: boolean) {
    // this should only be called to update the value of the fullyAutomated flag
    // the value should be initialized using setShopper() from FETCH_CATALOG
    // this function should only ever reset state.shopper.fullyAutomated to false
    state.shopper.fullyAutomated = fullyAutomated && state.shopper.fullyAutomated
  },
  updateInstallOption(state: State, payload: InstallOption | undefined) {
    state.shopper.cart.schedule ??= newSchedule()
    state.shopper.cart.schedule.installOption = payload
  },
  updateAccountInfoValid(state: State, valid: boolean) {
    state.validation.accountInfo = valid
  },
  updatePhoneInfoValid(state: State, valid: boolean) {
    state.validation.phoneInfo = valid
  },
  updatePhoneInfo(state: State, phoneNumber: string) {
    state.shopper.cart.phone.phoneNumber = phoneNumber
    state.shopper.cart.phone.phoneNumberDetails = parsePhoneNumber(phoneNumber)
  },
  setPromo(state: State, promo: Promo | undefined) {
    if (promo) {
      state.shopper.cart.promo = promo
      state.showBanner = false
      ecom.pushPromoClick(promo)
      // no ga4 here because we need to account for creative_slot
    }
  },
  removePromo(state: State, value: any) {
    state.shopper.cart.promo = undefined
    state.showBanner = true
  },
  [SET_MATCHED_ADDRESS](state: State, matchedAddress: FullAddress | undefined) {
    state.serviceability.matchedAddress = matchedAddress
    if (matchedAddress && matchedAddress.matchType === MatchType.EXACT) {
      state.serviceability.addressVerified = true
    }
    state.shopper.matchedAddress = matchedAddress
  },
  setShowBanner(state: State, show: boolean) {
    state.showBanner = show
  },
  addGaEvent(state: State, event: GtmEvent) {
    state.gaEvents.push(event)
  },
  setUrlParams(state: State, params: UrlParams | undefined) {
    state.UrlParams = params
    state.shopper.tags ??= {}
    state.shopper.tags.urlParams = params
  },
  setServiceability(state: State, params: ServiceabilityState) {
    state.serviceability = params
  },
  setUIConfig(state: State, params: UIConfig) {
    state.config = params
  },
  setQualityScore(state: State, { score, time, status }: { score: number; time: number; status: QualityScoreStatus }) {
    state.shopper.qualityScore = score
    state.shopper.qualityScoreTime = time
    state.shopper.qualityScoreStatus = status
  },
  setQualityScoreStatus(state: State, status: QualityScoreStatus) {
    state.shopper.qualityScoreStatus = status
  },
  setConfigItems(state: State, items: ConfigItems) {
    state.configItems = items
  },
  setExistingServiceOption(state: State, newOption: string) {
    state.shopper.existingServiceOption = newOption
  },
  setValidation(state: State, newVal: Validation) {
    state.validation = newVal
  },
  updateShopperLog(state: State, msg: string) {
    state.shopper.log ??= []
    state.shopper.log.push(msg)
    const ps = usePiniaShopper().shopper
    ps.log ??= []
    ps.log.push(msg)
  },
  updateShopperAgentNotes(state: State, note: Note) {
    state.shopper.agentNotes ??= []
    state.shopper.agentNotes.push(note)
    const ps = usePiniaShopper().shopper
    ps.agentNotes ??= []
    ps.agentNotes.push(note)
  },
  setCancelAutomation(state: State, cancelAutomation: boolean) {
    state.shopper.cancelAutomation = cancelAutomation
    usePiniaShopper().shopper.cancelAutomation = cancelAutomation
  },
  setUpdatesFromIntegration(state: State, updates: UpdatesFromIntegration) {
    if (updates.shopper) {
      if (updates.shopper.externalAccountNumber) {
        state.shopper.externalAccountNumber = updates.shopper.externalAccountNumber
        ga4.setDataLayerVariable({
          event: 'external_account_changed',
          externalAccountNumber: updates.shopper.externalAccountNumber
        })
      }
      if (updates.shopper.externalWorkOrder) {
        state.shopper.externalWorkOrder = updates.shopper.externalWorkOrder
      }
      if (updates.shopper.customInfo) {
        const prevCustomInfo = state.shopper.customInfo
        state.shopper.customInfo = { ...prevCustomInfo, ...updates.shopper.customInfo }
      }
      if (updates.shopper.merge) {
        if (updates.shopper.merge.tags) {
          state.shopper.tags = { ...state.shopper.tags, ...updates.shopper.merge.tags }
        }
        if (updates.shopper.merge.matchResults && state.shopper.matchResults?.results) {
          state.shopper.matchResults.results.push(...updates.shopper.merge.matchResults.results)
        }
      }
      if (updates.shopper.riskScoreResults) {
        state.shopper.riskScore = updates.shopper.riskScoreResults.riskScore
        state.shopper.riskScoreDetails = updates.shopper.riskScoreResults.riskScoreDetails
        state.shopper.riskScoreSource = updates.shopper.riskScoreResults.riskScoreSource
      }
    }
  }
}

interface Action {
  state: State
  commit: (...args: any) => any
  dispatch: (...args: any) => any
}

const actions = {
  async getPreviousShopper({ commit }: Action, { id, type }: { id: string; type: 'exId' | 'id' }) {
    const previousShopperApiConfig: ApiConfig | undefined = getConfigItem(ConfigKeys.previousShopperApiConfig)
    // commit('setSpinnerUpdateTime', previousShopperApiConfig?.messageTimeout ?? 10)
    // commit('setSpinners', previousShopperApiConfig?.messages ?? ['Retrieving previous order...'])
    const previousShopperSpinner: Spinner = {
      id: 'previousShopper',
      messages: previousShopperApiConfig?.messages ?? ['Retrieving previous order...'],
      spinnerUpdateTime: previousShopperApiConfig?.messageTimeout ?? 10,
      rank: 1
    }
    commit('addSpinner', previousShopperSpinner)
    logger.info('getPreviousShopper', id)
    try {
      let response
      if (type === 'exId') {
        response = await httpClient.get(`/api/getShopperByExternalId/${id}`, makeAxiosConfig(previousShopperApiConfig))
      } else {
        response = await httpClient.get(`/api/getShopperbyId/${id}`, makeAxiosConfig(previousShopperApiConfig))
      }
      const prevShopper: Partial<IShopper> = response.data
      const prevState: SavedState | undefined = prevShopper.state
      if (prevState !== undefined && isCompatibleShopper(prevShopper)) {
        prevShopper.state = undefined // don't keep around previous state
        commit(SET_SHOPPER, prevShopper)
        commit('setOrderId', prevShopper.orderNumber)
        commit('setConfigItems', prevState.configItems)
        commit(SET_CATALOG, prevShopper.catalog)
        usePiniaShopper().setShopper(prevShopper)
        commit('setInitialPreSale', false)
        commit('setReturningPreSale', true)
        usePiniaShopper().shopper.customInfo.initialPreSale = false
        usePiniaShopper().shopper.customInfo.returningPreSale = true
        commit('setCancelAutomation', false)
        // will potentially need to update
        // commit(SET_ORDER, prevShopper.order)
        commit('setServiceability', prevState.serviceability)
        commit('setAvailableSchedule', prevState.schedule)
        commit('setValidation', prevState.validation)
        // if (state.UrlParams.ro) {
        //   commit('setPreSale', false)
        //   commit('setInitialPreSale', false)
        //   commit('setReturningPreSale', false)
        //   usePiniaShopper().shopper.customInfo.preSale = false
        //   usePiniaShopper().shopper.customInfo.initialPreSale = false
        //   usePiniaShopper().shopper.customInfo.returningPreSale = false
        //   bus.$emit('goToOffers')
        // } else
        const compatibleRoute = getConfigString(ConfigKeys.compatiblePreSaleShopperRoute) || 'goToScheduling'
        bus.$emit(prevShopper.status === 'submittedOrder' ? 'goToConfirmation' : compatibleRoute)
      } else {
        commit('setPreSale', false)
        commit('setInitialPreSale', false)
        commit('setReturningPreSale', false)
        usePiniaShopper().shopper.customInfo.preSale = false
        usePiniaShopper().shopper.customInfo.initialPreSale = false
        usePiniaShopper().shopper.customInfo.returningPreSale = false
        const ma = usePiniaShopper().shopper.matchedAddress
        if (ma === undefined) {
          usePiniaShopper().shopper.matchedAddress = { salesType: 'none' }
        } else {
          ma.salesType = 'none'
        }
        const incompatibleRoute = getConfigString(ConfigKeys.incompatiblePreSaleShopperRoute) || 'goToPreSale'
        ecom.pushPageView('/presaleError')
        ga4.pushPageView('/presaleError')
        bus.$emit(incompatibleRoute)
        logger.info(`invalid previous shopper:`, prevShopper)
      }
    } catch (e) {
      const incompatibleRoute = getConfigString(ConfigKeys.incompatiblePreSaleShopperRoute) || 'goToPreSale'
      ecom.pushPageView('/presaleError')
      ga4.pushPageView('/presaleError')
      bus.$emit(incompatibleRoute)
      //console.log('error', e)
      logger.info(`no previous shopper found ${e}`)
    } finally {
      commit('removeSpinner', 'previousShopper')
    }
  },
  async getUIConfig({ state, commit }: Action) {
    //logger.info('getUIConfig')
    const uiConfig = await usePiniaUIConfig().fetchUIConfig()
    // Set Vuex UIConfig from Pinia
    if (uiConfig) {
      commit('setUIConfig', uiConfig)
      // if (uiConfig.shouldCreditCheck) {
      //   commit('addCreditCheck', { softCreditCheck: uiConfig.shouldCreditCheck })
      // }
      commit('setConfigItems', allConfigItems())
    }
  },
  async getReCaptchaToken({ commit }: Action) {
    const captchaApiConfig: ApiConfig | undefined = getConfigItem(ConfigKeys.captchaApiConfig) ?? {}
    if (!captchaApiConfig?.enable) {
      commit('setQualityScore', { score: 1.0, time: 0, status: 'disabled' })
      return
    }
    const startTime = new Date().getTime()
    try {
      await grecaptcha.enterprise.ready(async function () {
        await grecaptcha.enterprise
          .execute(captchaApiConfig?.publicKey, { action: 'submit' })
          .then(async (token: string) => {
            try {
              const riskAssessment: any = (
                await httpClient.post('/api/verifyCaptcha', { token }, makeAxiosConfig(captchaApiConfig))
              ).data
              const endTime = new Date().getTime()
              if (riskAssessment?.riskAnalysis?.score !== undefined) {
                const score: number = riskAssessment.riskAnalysis.score
                commit('setQualityScore', { score, time: endTime - startTime, status: 'received' })
                ga4.pushCaptchaEvent(score, endTime - startTime)
              } else {
                logger.error('riskAssessment failed', JSON.stringify(riskAssessment))
                throw new Error('riskAssessment failed 0')
              }
            } catch (err) {
              const errorEndTime = new Date().getTime()
              commit('setQualityScore', { score: 1.0, time: errorEndTime - startTime, status: 'failure' })
              ga4.pushCaptchaEvent(1.0, errorEndTime - startTime)
              logger.error('riskAssessment failed 1', err)
            }
          })
          .catch((e) => {
            const errorEndTime = new Date().getTime()
            commit('setQualityScore', { score: 1.0, time: errorEndTime - startTime, status: 'failure' })
            ga4.pushCaptchaEvent(1.0, errorEndTime - startTime)
            logger.error('reCaptcha failed 2', e)
          })
      })
    } catch (e) {
      const errorEndTime = new Date().getTime()
      commit('setQualityScore', { score: 1.0, time: errorEndTime - startTime, status: 'failure' })
      ga4.pushCaptchaEvent(1.0, errorEndTime - startTime)
      logger.error('reCaptcha failed 4', e)
    }
  },
  async [FETCH_CATALOG]({ state, commit, dispatch }: Action, inputAddress: string) {
    const catalogApiConfig: ApiConfig | undefined = getConfigItem(ConfigKeys.catalogApiConfig) ?? {}
    const captchaApiConfig: ApiConfig | undefined = getConfigItem(ConfigKeys.captchaApiConfig) ?? {}

    logger.info('Fetching catalog', inputAddress)
    logger.info('debug fetching address', inputAddress)
    ga4.pushAddressEntered(inputAddress)

    commit('setServiceability', initialServiceablity())
    const reqaddr: CatalogAddress = {}
    reqaddr.inputAddress = inputAddress
    const urlParams = state.UrlParams
    const preFetchTags = state.shopper.tags?.preFetch ?? {}
    preFetchTags.qualityScore = state.shopper.qualityScore
    let qualityScoreStatus = state.shopper.qualityScoreStatus
    if (!captchaApiConfig?.enable) {
      qualityScoreStatus = 'disabled' // this is to cover the race condition where getRecapcha is called at the same time as fetch catalog
      await commit('setQualityScoreStatus', qualityScoreStatus)
    }
    if (state.shopper.qualityScoreStatus === 'received') {
      qualityScoreStatus = 'success'
      await commit('setQualityScoreStatus', qualityScoreStatus)
    }
    preFetchTags.qualityScoreStatus = qualityScoreStatus
    reqaddr.tags = {
      shopperSubtype: state.shopper.customInfo?.isStudent ? 'student' : undefined,
      creditFlag: 'true',
      urlParams,
      preFetch: preFetchTags
    }
    const uuid = uuidv4()
    const body: FetchCatalogRequest = {
      inputAddress: reqaddr,
      shopper: state.shopper,
      referrer: state.referrer,
      uuid,
      place: state.shopper.place
    }
    const response = await httpClient.post('/api/getCatalog', body, makeAxiosConfig(catalogApiConfig))
    const deRot = rot13Cipher(response.data)
    const catalogFetchResult: FetchCatalogResult = JSON.parse(uuencode.decode(deRot))

    const { statusCode, statusMessage, shopper } = catalogFetchResult

    // TODO: Is this needed? We init shopperDelta again below.
    shopperDelta(shopper, true)

    if (state.UrlParams?.ro) {
      //console.log(state.shopper.customInfo)
      shopper.firstName = state.shopper.firstName
      shopper.lastName = state.shopper.lastName
      shopper.email = state.shopper.email
      shopper.phone = state.shopper.phone
      shopper.customInfo = state.shopper.customInfo
      shopper.customInfo.creditCheckPassed = false
      shopper.customInfo.creditChecks = []
    }
    const { matchedAddress, catalog } = shopper
    // console.log('catalogShopper', shopper)
    if (usePiniaShopper().shopper.customInfo.isStudent) {
      shopper.customInfo.isStudent = usePiniaShopper().shopper.customInfo.isStudent
    }
    const { proceedToBuyFlow, isSalesLead } = useServiceability()
    if (statusCode === 'FAILURE') {
      throw new Error(statusMessage)
    }
    if (catalog && matchedAddress) {
      commit(SET_CATALOG, catalog)
      commit('setConfigItems', allConfigItems())
      const salesLead = isSalesLead(matchedAddress, catalog) ? getSalesLeadInfo(catalog) || state.config.salesLead : undefined
      commit(SET_SALES_LEAD, salesLead)
      commit(SET_MATCHED_ADDRESS, matchedAddress)
      commit(UPDATE_SHOPPER_INFO, shopper)
      commit('updateShopperAddress', placeFromMatchedAddress(matchedAddress))
      usePiniaShopper().setShopper(cloneDeep(shopper))

      // at this point we have a new shopper, so reset shopperDelta state so that full shopper data will be resent
      shopperDelta(shopper, true)

      commit(SET_ADDRESS_VERIFIED, proceedToBuyFlow(matchedAddress, shopper, catalog))
    }
    const isPreSale = getConfigBoolean(ConfigKeys.isPreSale) && getConfigBoolean(ConfigKeys.enablePreSale) && !state.UrlParams?.ro
    if (isPreSale) {
      commit('setSalesType', SalesType.PRESALE)
      commit('setPreSale', true)
      commit('setInitialPreSale', true)
      commit('setReturningPreSale', false)
      usePiniaShopper().shopper.customInfo.preSale = true
      usePiniaShopper().shopper.customInfo.initialPreSale = true
      usePiniaShopper().shopper.customInfo.returningPreSale = false
      if (getConfigBoolean(ConfigKeys.preSaleDisableAutomation)) {
        dispatch('cancelAutomation', 'Automation cannot be completed on initial presale order')
      }
    }
    if (shopper.tags?.salesType === 'upgrade') {
      commit('setSalesType', SalesType.UPGRADE)
    }
    if (shopper.tags?.salesChannel === 'leadCapture') {
      commit('setSalesType', SalesType.LEADCAPTURE)
    }
    commit('setSelfInstall', getConfigBoolean(ConfigKeys.canSelfInstall))
    usePiniaShopper().shopper.customInfo.canSelfInstall = getConfigBoolean(ConfigKeys.canSelfInstall)
    //commit('setConfigItems', allConfigItems())
    if (statusCode === 'DUBIOUS') {
      dispatch('cancelAutomation', 'Catalog could not be merged')
    }
    const shouldCreditCheck = getConfigItem(ConfigKeys.shouldCreditCheck)
    if (shouldCreditCheck) {
      commit('addCreditCheck', { softCreditCheck: shouldCreditCheck })
    }
    //console.log('FETCH DONE')
  },
  async submitOrder({ state, commit, dispatch }: Action) {
    try {
      const submitOrderApiConfig: ApiConfig | undefined = getConfigItem(ConfigKeys.submitOrderApiConfig)
      // commit('setSpinnerUpdateTime', submitOrderApiConfig?.messageTimeout ?? 10)
      // commit('setSpinners', submitOrderApiConfig?.messages ?? ['Submitting your order'])
      const submitSpinner: Spinner = {
        id: 'submitSpinner',
        messages: submitOrderApiConfig?.messages ?? ['Submitting your order'],
        spinnerUpdateTime: submitOrderApiConfig?.messageTimeout ?? 10,
        rank: 2
      }
      commit('addSpinner', submitSpinner)
      const order = state.order.cartData
      logger.info('submitOrder')
      const emailInfo: EmailInfo = {
        emailTemplate: getConfigItem(ConfigKeys.emailTemplate),
        subject: getConfigItem(ConfigKeys.emailSubject)
      }
      const shopperToSubmit: IShopper = { ...state.shopper, logMsg: 'Submit Order', state: getStateToSave(state) }
      delete shopperToSubmit.idImage
      shopperToSubmit.status =
        shopperToSubmit.customInfo?.preSale && !shopperToSubmit.customInfo?.returningPreSale ? 'submittedPreSale' : 'submittedOrder'
      const body: SubmitOrderData = {
        order,
        shopperId: state.shopper.id,
        shopper: shopperDelta(shopperToSubmit, true),
        emailInfo,
        configItems: allConfigItems()
      }
      // const axiosConfig = (): AxiosRequestConfig => {
      //   const axiosTimeout = getConfigItem(ConfigKeys.axiosTimeout)
      //   if (!axiosTimeout) return {}
      //   return {
      //     timeout: axiosTimeout
      //   }
      // }

      const response = await httpClient.put('/api/submitOrder', body, makeAxiosConfig(submitOrderApiConfig))

      //console.log('id = ', response.data.id)
      //state.order.id = response.data.id
      commit('setOrderId', response.data.id)
      commit('setShowBanner', false)
      ecom.pushPurchase(response.data.id, order)
      ga4.pushPurchase()
      // store all GA events in our db
      await httpClient.put(`/api/gaEvents/${state.shopper.id}`, state.gaEvents).catch((e) => e) //this .catch allows ga calls to fail silently after retries
      // send request to submit GA data to Google via our backend (won't send if it already was)
      await httpClient.put(`/api/gaSubmitOrder/${state.order.id}`).catch((e) => e) //this .catch allows ga calls to fail silently after retries
    } catch (e) {
      throw e
    } finally {
      commit('removeSpinner', 'submitSpinner')
    }
  },
  async addGa4Event({ state }: Action, payload: GA4EventWrapper) {
    //logger.info(`sending ga4 event to backend: ${JSON.stringify(payload)}`)
    try {
      await httpClient.put(`/api/ga4Event?shopperId=${state.shopper.id}`, payload)
    } catch (e) {
      // dont throw - allow ga calls to fail silently after retries
      logger.warn(`ga4 event failed: ${payload.event}`)
    }
  },
  async updateShopper({ commit, state }: Action, message: string) {
    logger.info('updateShopper', message)
    const shopperMsg: Partial<IShopper> = { logMsg: message }
    const newShopperToSubmit: IShopper = { ...state.shopper, ...shopperMsg, state: getStateToSave(state) }
    const delta = shopperDelta(newShopperToSubmit)
    const response = await httpClient.put('/api/shopper', delta)
    commit('setOrderId', response.data.order?.id)
  },
  async [FETCH_ADDRESS_SUGGESTIONS](
    { state, commit }: Action,
    payload: { search: string; selected: string; extraAutoCompleteParams: string }
  ) {
    const { search, selected, extraAutoCompleteParams } = payload

    //logger.info('Fetching address suggestions', search)

    let url = `${state.config.addressSearchUrl}?${new URLSearchParams({ search })}`
    if (selected) url = `${url}&${new URLSearchParams({ selected })}`
    if (extraAutoCompleteParams) url = `${url}&${new URLSearchParams(extraAutoCompleteParams)}`
    try {
      const response = await axios.get<SmartyStreetsLookupResponse>(url)
      // const response = await httpClient.get(url);
      const suggestions: Place[] =
        response.data.suggestions && response.data.suggestions.length > 0
          ? response.data.suggestions
          : [{ street_line: search, entries: 0 }]
      suggestions.forEach((a) => {
        delete a.source // remove source key if it exists
      })
      if (state.config.joinAddressEntries) {
        suggestions.forEach((a, i) => {
          //remove billing address when there are multiple entries
          const hasEntriesDuplicate = suggestions.find(
            (aWithEntries) =>
              a.city === aWithEntries.city &&
              a.state === aWithEntries.state &&
              a.zipcode === aWithEntries.zipcode &&
              a.street_line === aWithEntries.street_line &&
              aWithEntries.entries
          )
          if (hasEntriesDuplicate && !a.entries) {
            suggestions.splice(i, 1)
          }
        })
        if (suggestions.every((a) => a.entries === 1)) {
          suggestions.push({
            // add billing address to list of multilple entries returned from secondary search
            ...suggestions[0],
            entries: 0,
            secondary: ''
          })
        }
      }
      commit(SET_ADDRESS_SUGGESTIONS, suggestions)
    } catch (e) {
      commit('updateShopperAddress', { full: search })
    }
  },
  async [FETCH_PREVIOUS_ADDRESS_SUGGESTIONS]({ state, commit }: Action, payload: { search: string; selected: string }) {
    logger.info('Fetching previous address suggestions')
    const { search, selected } = payload
    let url = `${state.config.addressSearchUrl}?search=${search}`
    if (selected) url = `${url}&selected=${selected}`
    try {
      const response = await axios.get(url)
      // const response = await httpClient.get(url);
      const suggestions =
        response.data.suggestions && response.data.suggestions.length > 0
          ? response.data.suggestions
          : [{ street_line: search, entries: 0 }]
      commit(SET_PREVIOUS_ADDRESS_SUGGESTIONS, suggestions)
    } catch (e) {
      commit('updateShopperPreviousAddress', { full: search })
    }
  },
  async [FETCH_BILLING_ADDRESS_SUGGESTIONS]({ state, commit }: Action, payload: { search: any; selected: any }) {
    logger.info('Fetching billing address suggestions')
    const { search, selected } = payload
    let url = `${state.config.addressSearchUrl}?search=${search}`
    if (selected) url = `${url}&selected=${selected}`
    try {
      const response = await axios.get(url)
      // const response = await httpClient.get(url);
      const suggestions =
        response.data.suggestions && response.data.suggestions.length > 0
          ? response.data.suggestions
          : [{ street_line: search, entries: 0 }]
      commit(SET_BILLING_ADDRESS_SUGGESTIONS, suggestions)
    } catch (e) {
      commit('updateShopperBillingAddress', { full: search })
    }
  },
  async updateServiceabilityChoice({ dispatch, commit }: Action, choice: string) {
    logger.info('updateServiceabilityChoice')
    commit('setExistingServiceOption', choice)
    dispatch('updateShopper', 'updateServiceabilityChoice')
  },
  async timeslots({ dispatch, commit, state }: Action, payload: SchedulingConfig) {
    try {
      const timeslotsApiConfig: ApiConfig | undefined = getConfigItem(ConfigKeys.timeslotsApiConfig)
      const shopperMsg: Partial<IShopper> = { logMsg: 'update Shopper fetching timeslot info' }
      const newShopperToSubmit: IShopper = { ...state.shopper, ...shopperMsg, state: getStateToSave(state) }
      const delta = shopperDelta(newShopperToSubmit, false)
      const tsReq: TimeSlotData = {
        shopperId: state.shopper.id,
        shopperData: delta,
        scheduling: payload
      }
      logger.info('Fetch timeslots')

      const timeslotsResponse = await makeIdempotentRetryRequest('/api/timeslots', tsReq, timeslotsApiConfig)
      //const { automationState, data }: AutomationInfo<any> = (await axios.post('/api/timeslots', tsReq)).data

      const axiosRes: AutomationInfo<AutomationSchedule> | undefined = timeslotsResponse?.data

      if (axiosRes?.automationState) {
        // Don't detect errors and cancel Automation here ... handleTimeslots does it already
        commit('updateFullyAutomated', goodStatus(axiosRes.automationState.status))
        commit('setShopperAutomationState', axiosRes.automationState)
        //dispatch('updateShopper', 'Fetch timeslots')

        const updatesFromIntegration =
          axiosRes.automationState.schedule?.updatesFromIntegration ?? axiosRes.data.updatesFromIntegration
        if (updatesFromIntegration) {
          commit('setUpdatesFromIntegration', updatesFromIntegration)
          if (updatesFromIntegration.cancelAutomation) {
            await dispatch('cancelAutomation', updatesFromIntegration.cancelAutomation.msg)
          }
          await dispatch('updateShopper', 'Updates from scheduling integration')
        }
      }
      return axiosRes?.data
    } catch (e) {
      logger.error(`Fetch timeslots failed: ${e}`)
      throw e
    }
  },
  async creditCheck({ dispatch, commit, state }: Action, cctype?: CreditCheckType) {
    cctype ??= 'soft'
    const shopperMsg: Partial<IShopper> = { logMsg: `update Shopper doing creditCheck type: ${cctype}` }
    const newShopperToSubmit: IShopper = { ...state.shopper, ...shopperMsg, state: getStateToSave(state) }
    const delta = shopperDelta(newShopperToSubmit)
    const ccData: CreditCheckData = { shopper: delta, creditCheckType: cctype }
    logger.info(`Perform (${cctype}) credit check`)
    const automationInfo: AutomationInfo<LegacyAutomationState> = (
      await httpClient.post<AutomationInfo<LegacyAutomationState>>(
        '/api/performCreditCheck',
        ccData,
        makeAxiosConfig(getConfigItem(ConfigKeys.creditCheckApiConfig))
      )
    ).data
    if (automationInfo.automationState) {
      commit('setShopperAutomationState', automationInfo.automationState)
      //dispatch('updateShopper', 'Perform credit check automationState update')
    }
    if (automationInfo.data?.softCreditCheck) {
      ga4.pushCreditCheck(automationInfo.data.softCreditCheck)
    }
    if (automationInfo.data?.softCreditCheck?.updatesFromIntegration) {
      commit('setUpdatesFromIntegration', automationInfo.data.softCreditCheck.updatesFromIntegration)
      dispatch('updateShopper', 'Updates from scheduling integration')
    }
    return automationInfo.data
  },
  async initializePayment({ dispatch, commit, state }: Action, disposition?: Partial<PaymentDisposition>) {
    const initializePaymentApiConfig: ApiConfig | undefined = getConfigItem(ConfigKeys.initializePaymentApiConfig)
    // commit('setSpinnerUpdateTime', initializePaymentApiConfig?.messageTimeout ?? 10)
    // commit('setSpinners', initializePaymentApiConfig?.messages ?? [''])
    const initializeSpinner: Spinner = {
      id: 'initializeSpinner',
      messages: initializePaymentApiConfig?.messages ?? ['Initalizing Payment'],
      spinnerUpdateTime: initializePaymentApiConfig?.messageTimeout ?? 10,
      rank: 1
    }
    commit('addSpinner', initializeSpinner)
    const shopperMsg: Partial<IShopper> = { logMsg: 'update Shopper initializing payment' }
    const newShopperToSubmit: IShopper = { ...state.shopper, ...shopperMsg }
    const shopperUpdate = shopperDelta(newShopperToSubmit)
    const paymentConfig: PaymentConfig = getConfigItem(ConfigKeys.paymentConfig)

    try {
      assertNotNull(state.shopper.tags?.serviceAddress, 'Shopper serviceAddress is null')
      assertNotNull(state.shopper.firstName, 'Shopper first name is null')
      assertNotNull(state.shopper.lastName, 'Shopper last name is null')
      assertNotNull(state.shopper.email, 'Shopper email is null')
      assertNotNull(state.shopper.phone, 'Shopper phone is null')

      paymentConfig.accountFields?.forEach((field) => {
        if (
          field.defaultStoreProp &&
          getElement(state.shopper, field.defaultStoreProp) &&
          field.storeProp &&
          !getElement(state.shopper, field.storeProp)
        ) {
          const defaultVal = getElement(state.shopper, field.defaultStoreProp)
          commit('setAccountField', { field: field.storeProp, value: defaultVal })
        }
      })

      paymentConfig.billingAccountFields?.forEach((field) => {
        if (
          field.defaultStoreProp &&
          getElement(state.shopper, field.defaultStoreProp) &&
          field.storeProp &&
          !getElement(state.shopper, field.storeProp)
        ) {
          const defaultVal = getElement(state.shopper, field.defaultStoreProp)
          commit('setAccountField', { field: field.storeProp, value: defaultVal })
        }
      })

      const initialDisposition: PaymentDisposition = {
        done: false,
        transactionId: null,
        status: 'initial',
        authorization: null,
        errors: [],
        processorDisposition: {
          type: 'Generic',
          data: null
        },
        billerDisposition: null,
        paymentContact: {
          firstName: state.shopper.firstName,
          lastName: state.shopper.lastName,
          email: state.shopper.email,
          phone: state.shopper.phone,
          address: {
            addressLine: state.shopper.tags?.serviceAddress.addressLine1and2,
            state: state.shopper.tags?.serviceAddress.state,
            city: state.shopper.tags?.serviceAddress.city,
            zip: state.shopper.tags?.serviceAddress.zip
          },
          externalAccountNumber: state.shopper.externalAccountNumber ?? undefined
        },
        paymentMethod: 'cc'
      }

      const paymentData: ADGPaymentInitializeData = { disposition: { ...initialDisposition, ...disposition } }
      const paymentRequest: ADGPaymentInitializerRequest = { shopperUpdate, paymentData }
      logger.info('Initialize payment')
      const automationInfo: ADGPaymentInitializerResponse = (
        await httpClient.post('/api/payment/initialize', paymentRequest, makeAxiosConfig(initializePaymentApiConfig))
      ).data
      // if (automationInfo.automationState) {
      //   commit('setShopperAutomationState', automationInfo.automationState)
      //   //dispatch('updateShopper', 'Initialize payment automationState update')
      // }
      // if (automationInfo.data?.updatesFromIntegration) {
      //   commit('setUpdatesFromIntegration', automationInfo.data.updatesFromIntegration)
      //   dispatch('updateShopper', 'Updates from scheduling integration')
      // }
      if (automationInfo.disposition) {
        commit('setPaymentDisposition', automationInfo.disposition)
      }
      commit('setPaymentInitializer', automationInfo)
    } catch (e) {
      logger.error('Initialize payment failed', e)
      throw e
    } finally {
      commit('removeSpinner', 'initializeSpinner')
    }
  },
  async finalizePayment({ dispatch, commit, state }: Action, payload: PaymentDisposition) {
    const finalizePaymentApiConfig: ApiConfig | undefined = getConfigItem(ConfigKeys.finalizePaymentApiConfig)
    const paymentConfig: PaymentConfig = getConfigItem(ConfigKeys.paymentConfig)

    // commit('setSpinnerUpdateTime', finalizePaymentApiConfig?.messageTimeout ?? 10)
    // commit('setSpinners', finalizePaymentApiConfig?.messages ?? ['Processing Payment'])
    const finalizeSpinner: Spinner = {
      id: 'finalizeSpinner',
      messages: finalizePaymentApiConfig?.messages ?? ['Processing Payment'],
      spinnerUpdateTime: finalizePaymentApiConfig?.messageTimeout ?? 10,
      rank: 1
    }
    commit('addSpinner', finalizeSpinner)
    try {
      const shopperMsg: Partial<IShopper> = { logMsg: 'update Shopper finalizing payment' }
      const newShopperToSubmit: IShopper = { ...state.shopper, ...shopperMsg }
      const shopperUpdate = shopperDelta(newShopperToSubmit)
      const paymentData: ADGPaymentFinalizer = payload
      // since this method can potentially get called more than once, we need to clear out previous errors
      paymentData.errors = []
      const paymentRequest: ADGPaymentFinalizerRequest = { shopperUpdate, paymentData }
      logger.info('Finalize payment')
      const automationInfo: PaymentDisposition | undefined = (
        await httpClient.post('/api/payment/finalize', paymentRequest, makeAxiosConfig(finalizePaymentApiConfig))
      )?.data

      if (automationInfo?.cancelAutomation) {
        if (paymentConfig.disableErrorSubmit) {
          logger.warn('Automation cancelation blocked: payment error submission is disabled')
          automationInfo.cancelAutomation = false
        } else {
          await dispatch('cancelAutomation', 'Payment cancelled')
        }
      }
      // console.log('finalizePayment', automationInfo)
      return automationInfo
    } catch (e) {
      commit('removeSpinner', 'Processing Payment')
      logger.error('Finalize payment failed', e)
      await dispatch('cancelAutomation', 'Finalize payment failed')
      return undefined
    } finally {
      commit('removeSpinner', 'finalizeSpinner')
    }
  },
  async remoteLogging({ state, commit }: Action, payload: RemoteLoggingMessage) {
    // Log locally to shopper.log and then send to remote logging service
    payload.shopperId = state.shopper.id
    payload.source = 'ui'
    let msg = `level="${payload.level}" msg="${payload.msg}"`
    if (payload.args.length > 0) {
      msg = `${msg} args=${JSON.stringify(payload.args)}`
    }
    commit('updateShopperLog', msg)
    httpClient.put('/api/remoteLogging', payload)
  },
  async cancelAutomation({ commit, state, dispatch }: Action, msg: string = 'Client Cancelled') {
    if (!state.shopper.fullyAutomated) return
    commit('setCancelAutomation', true)
    commit('updateFullyAutomated', false)
    const cancelMsg = `Automation cancelled: reason '${msg}'`
    const agentNote: Note = {
      agent: 'System',
      timestamp: new Date(),
      msg: cancelMsg
    }
    commit('updateShopperAgentNotes', agentNote)
    logger.info(cancelMsg)
    // KWC  -- had to comment this out ... it was causing a weird recursive call which caused Typeorm errors
    // I have no idea why
    //dispatch('updateShopper', 'cancelAutomation: ' + msg)
  },
  async authorizeToken({ commit, dispatch }: Action, token: string) {
    const response = await axios.get<AuthorizeTokenResponse>(`/api/authToken/${token}`)
    return response.data.status === 'success'
  },
  async processLeadCapture({ dispatch, commit, state }: Action, leadFormName: string): Promise<string> {
    await commit('setLeadFormName', leadFormName)
    ecom.pushLeadCaptureSubmitted()
    ga4.pushLeadCaptureSubmitted(leadFormName)
    const shopperMsg: Partial<IShopper> = { logMsg: 'update Shopper for hubSpot lead capture' }
    const newShopperToSubmit: IShopper = { ...state.shopper, ...shopperMsg, state: getStateToSave(state) }
    const delta = shopperDelta(newShopperToSubmit, false)
    const hubSpotLeadCaptureRequest: HubSpotLeadCaptureData = {
      shopperData: delta
    }
    try {
      const response = await httpClient.post('/api/hubspot/processLeadCapture', hubSpotLeadCaptureRequest)
      const hubSpotContactId: string = response.data
      commit('setAccountField', { field: 'hubSpotContactId', value: hubSpotContactId })
      usePiniaShopper().shopper.hubSpotContactId = hubSpotContactId
      return hubSpotContactId
    } catch (err) {
      logger.info('unable to submit lead capture to hubspot', err)
      throw err
    }
  },
  async addBroadbandLabel({ commit, state }: Action, createLabelPayload: CreateBroadbandLabelPayload) {
    try {
      const response = await httpClient.post('/api/broadbandLabel', createLabelPayload)
      return response.data
    } catch (err) {
      logger.info('unable to create label record', err)
      throw err
    }
  },
  async updateBroadbandLabel({ commit, state }: Action, updateLabelPayload: UpdateBroadbandLabelPayload) {
    try {
      const response = await httpClient.put('/api/broadbandLabel/update', updateLabelPayload)
      return response.data
    } catch (err) {
      logger.info('unable to update label record', err)
      throw err
    }
  }
  // async authorizeToken({ commit, dispatch }: Action, token: string) {
  //   const response = await httpClient.get(`/api/authToken/${token}`)
  //   if (response.data.status === 'failure') {
  //     logger.warn('token authorization failed')
  //     throw new Error(response.data.message)
  //   }
  // }
}

export default {
  state: store,
  getters,
  mutations,
  actions
}
