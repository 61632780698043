<template>
  <v-card
    class="package"
    :class="getCatalogItemClassName(packageData?.Name, 'Package')"
    :id="offerCardId(packageData, colorIndex)"
    v-on="isOfferTileClickable ? { click: () => selectPackage(packageData) } : {}"
    :color="packagePieces.coloredPackages ? offerHeaderColors[colorIndex % offerHeaderColors.length] : ''"
  >
    <v-container class="package-card-container">
      <TooltipDebug :name="packageData.Description" :item="packageData" />
      <v-row v-for="(item, i) in packagePieces.pieces" :key="'packagePieces-' + i" :class="item.rowClass">
        <v-col
          :class="itemClass(item, numberOfProducts(packageData))"
          :style="
            item.type === 'original-package-header'
              ? { 'background-color': backgroundColor(colorIndex, offerHeaderColors, offerHeaderSecondaryColors) }
              : ''
          "
          v-if="item.type !== 'under-price-text' || (item.type === 'under-price-text' && hasUnderPriceText)"
        >
          <div v-if="item.type === 'header'" class="header-text" v-html="headerText(item.headerTextType, packageData)"></div>
          <div v-if="item.type === 'color-header'" :class="getColorHeaderClass(packageData)"></div>
          <div v-if="item.type === 'above-price-text'" v-html="abovePriceText"></div>
          <div v-if="item.type === 'features' && displayFeatures(packageData, item)" :class="item.class">
            <div v-for="(packageFeaturePiece, i) in item.packageFeatureItems">
              <div
                v-if="
                  packageFeaturePiece.type === 'text' &&
                  getPackageFeatureText(packageData, packageFeaturePiece.parentName, packageFeaturePiece.childName)
                "
                v-html="getPackageFeatureText(packageData, packageFeaturePiece.parentName, packageFeaturePiece.childName)"
                :class="packageFeaturePiece.class"
              ></div>
              <div
                v-if="
                  packageFeaturePiece.type === 'image' &&
                  featureImage(packageData, packageFeaturePiece.parentName, packageFeaturePiece.childName)
                "
                :class="packageFeaturePiece.class"
              >
                <v-img :src="featureImage(packageData, packageFeaturePiece.parentName, packageFeaturePiece.childName)"></v-img>
              </div>
            </div>
          </div>
          <div
            v-if="item.type === 'original-package-header'"
            class="header-text"
            v-html="headerText(item.headerTextType, packageData)"
          ></div>
          <div v-else-if="item.type === 'description'">
            <div v-html="packageDescription(packageData)"></div>
            <TooltipDebug :name="packageData.Description" :item="packageData" />
          </div>
          <div v-else-if="item.type === 'speed'">
            {{ speed(packageData) + ' ' + speedUnits(packageData) }}
          </div>
          <div v-else-if="item.type === 'speed-separate-units'">
            <div class="speed-num">{{ speed(packageData) }}</div>
            <div class="speed-units">{{ speedUnits(packageData) }}</div>
          </div>
          <div v-else-if="item.type === 'internet-display-name'" v-html="internetProductDisplayName(packageData)"></div>
          <div v-else-if="item.type === 'price'" class="price-nowrap">
            <span v-if="!item.specialDollarSign">${{ priceParts(packageData)[0] }}</span>
            <span v-if="item.specialDollarSign">
              <sup class="dollar-sign">$</sup>
              <span class="dollars">{{ priceParts(packageData)[0] }}</span>
            </span>
            <sup id="package-cents" class="cents" v-if="!(priceParts(packageData)[1] === '00' && hidePackagePriceZeroes)">{{
              item.showDotInPrice ? '.' + priceParts(packageData)[1] : priceParts(packageData)[1]
            }}</sup>
            <sup class="duration" v-html="duration(packageData)"></sup>
          </div>
          <v-row v-else-if="item.type === 'price-separated'" class="price-nowrap">
            <v-col :class="item.class" cols="12">
              <span v-for="priceItem in item.pricePieces">
                <span v-if="priceItem.type === 'dollar-sign-separated'" :class="priceItem.class">$</span>
                <span v-if="priceItem.type === 'dollars-separated'" :class="priceItem.class">{{ priceParts(packageData)[0] }}</span>
                <span v-if="priceItem.type === 'decimal-separated'" :class="priceItem.class">.</span>
                <sup
                  v-if="priceItem.type === 'cents-separated' && !(priceParts(packageData)[1] === '00' && hidePackagePriceZeroes)"
                  :class="priceItem.class"
                  >{{ priceParts(packageData)[1] }}</sup
                >
                <span v-if="priceItem.type === 'duration-separated'" :class="priceItem.class" v-html="duration(packageData)"></span>
              </span>
            </v-col>
          </v-row>
          <div v-else-if="item.type === 'divider'">
            <v-divider></v-divider>
          </div>
          <div v-else-if="item.type === 'image'"><v-img class="image" :src="image(packageData)"></v-img></div>
          <div
            class="display-name-text"
            v-else-if="item.type === 'display-name'"
            v-html="uiMacroParser(shopper, packageDisplayName(packageData) ?? '')"
            :style="item.coloredDisplayName ? { '--color': displayNameTextColor } : ''"
          ></div>
          <div v-else-if="item.type === 'button'">
            <v-btn
              :class="
                item.buttonClass
                  ? item.buttonClass + ` ${getCatalogItemClassName(packageData?.Name, 'Package')}`
                  : `button ${getCatalogItemClassName(packageData?.Name, 'Package')}`
              "
              rounded
              dark
              :color="item.buttonColor ? item.buttonColor : getOfferButtonColor(item, colorIndex)"
              v-html="offerButtonText"
              v-on="!isOfferTileClickable ? { click: () => selectPackage(packageData) } : {}"
            >
            </v-btn>
          </div>
          <div v-else-if="item.type === 'under-price-text'" v-html="packageData['Under Price Text']"></div>
          <div v-else-if="item.type === 'marketing-text'">
            <v-list class="marketing-text-list" dense>
              <div v-for="index in 9" :key="index">
                <v-list-item class="list-item" v-if="getPackageMarketingTextAtIndex(packageData, index)">
                  <div>
                    <v-list-item-icon v-if="item.icon !== 'none'">
                      <v-icon
                        :large="item.isIconLarge === 'true' ? true : false"
                        :color="item.marketingTextIconColor ? item.marketingTextIconColor : 'black'"
                        class="list-icon"
                      >
                        {{ item.icon }}
                      </v-icon>
                    </v-list-item-icon>
                  </div>
                  <v-list-item-content class="list-item-content">
                    <v-list-item-title class="marketing-text-title">
                      <v-img
                        v-if="item.image"
                        :src="item.image"
                        :color="item.marketingTextIconColor ? item.marketingTextIconColor : 'black'"
                        :class="item.imageClass"
                      ></v-img>
                      <span class="marketing-text" v-html="getPackageMarketingTextAtIndex(packageData, index)"></span>
                      <Tooltip
                        :color="item.tooltipColor"
                        :tooltipMdiIcon="item.tooltipIcon"
                        :isTooltipDark="item.isTooltipDark"
                        class="tooltip"
                        :payload="{ Name: getPackageMarketingTextAtIndex(packageData, index) }"
                      />
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-list>
          </div>
        </v-col>
      </v-row>
      <v-row class="flex-end thumbnail" v-if="labelHtml && broadbandLabelPlacement === 'thumbnail' && packageData.BroadbandLabel">
        <v-col cols="8" class="label-thumbnail-text">
          <div v-html="uiMacroParser(shopper, broadbandLabelCTAText, true)"></div>
        </v-col>
        <v-col cols="4" class="label-thumbnail">
          <a @click="$emit('thumbnailClicked')">
            <v-img src="/api/content/label-thumbnail.png"></v-img>
          </a>
        </v-col>
      </v-row>
      <v-row class="label-footer" v-if="labelHtml && broadbandLabelPlacement === 'footer'">
        <v-col cols="12">
          <a @click="$emit('labelFooterClicked')">
            <div class="footer-text" v-html="uiMacroParser(shopper, broadbandLabelCTAText, true)"></div>
          </a>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script lang="ts">
import { computed, defineComponent } from '@vue/composition-api'
import $store from '@/store'
import usePackage from './usePackage'
import { Package } from '@adg/catalog/src/modules/Catalog'
import useUiConfig from '@/components/shared/useUiConfig'
import TooltipDebug from '@/components/shared/tooltip/TooltipDebug.vue'
import Tooltip from '@/components/shared/tooltip/Tooltip.vue'
import { getCatalogItemClassName } from '@adg/catalog/src/common/utils'
import { ConfigKeys } from '@adg/catalog/src/modules/Catalog'
import { getConfigItem } from '../../shared/getConfigItem'
import BroadbandLabelBase from './BroadbandLabelBase.vue'
import { uiMacroParser } from '@/utils/ShopperHelpers'
import { IShopper } from '@adg/catalog/src/modules/Shopper'

interface PackageProps {
  pkgHeight: number
  minWidth: number
  colorIndex: number
  packageData: Package
  filterPackagesCount: number
  hasUnderPriceText: boolean
  displayNameTextColor: string
}

export default defineComponent({
  name: 'PackageNew',
  props: {
    pkgHeight: Number,
    minWidth: Number,
    colorIndex: {
      type: Number,
      required: true
    },
    labelHtml: String,
    broadbandLabelPlacement: String,
    packageData: {
      type: Object as () => Package,
      required: true
    },
    filterPackagesCount: Number,
    hasUnderPriceText: Boolean,
    displayNameTextColor: String
  },
  components: {
    TooltipDebug,
    Tooltip,
    BroadbandLabelBase
  },
  setup(props: PackageProps, { emit, refs, root: { $vuetify } }) {
    const {
      selectPackage,
      offerButtonText,
      offerCardId,
      numberOfProducts,
      priceParts,
      duration,
      marketingTextArray,
      image,
      packageDescription,
      packageDisplayName,
      getOfferButtonColor,
      isOfferTileClickable,
      packagePieces,
      headerText,
      speed,
      speedUnits,
      hidePackagePriceZeroes,
      internetProductDisplayName,
      getColorHeaderClass,
      backgroundColor,
      itemClass,
      abovePriceText,
      getPackageMarketingTextAtIndex,
      displayFeatures,
      getPackageFeatureText,
      featureImage,
      broadbandConfigString,
      approvedBroadbandLabelClasses
    } = usePackage($store, $vuetify, emit)

    const shopper = computed(() => $store.getters.getShopper as IShopper)
    const { offerHeaderColors, offerButtonColors, offerHeaderSecondaryColors } = useUiConfig($store)

    const broadbandLabelCTAText = computed(() => getConfigItem(ConfigKeys.broadbandLabelCTAText) ?? 'Click to view broadband facts')

    return {
      getPackageMarketingTextAtIndex,
      selectPackage,
      priceParts,
      image,
      numberOfProducts,
      marketingTextArray,
      duration,
      offerCardId,
      packageDescription,
      packageDisplayName,
      headerText,
      speed,
      speedUnits,
      internetProductDisplayName,
      getColorHeaderClass,
      backgroundColor,
      itemClass,
      getOfferButtonColor,
      offerButtonColors,
      offerButtonText,
      isOfferTileClickable,
      packagePieces,
      hidePackagePriceZeroes,
      offerHeaderColors,
      offerHeaderSecondaryColors,
      abovePriceText,
      getCatalogItemClassName,
      displayFeatures,
      getPackageFeatureText,
      featureImage,
      uiMacroParser,
      shopper,
      broadbandConfigString,
      approvedBroadbandLabelClasses,
      broadbandLabelCTAText
    }
  }
})
</script>
<style scoped>
.package-card-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 0px;
}
.thumbnail {
  justify-content: flex-end;
}

.package {
  height: 100%;
}
.row {
  flex: 0 1 auto;
}
.flex-end {
  margin-top: auto;
}
.marketing-text-title {
  white-space: normal;
  flex-wrap: wrap;
}
.list-item-content {
  text-align: left;
}
.price-nowrap {
  white-space: nowrap;
}

.display-name-text {
  color: var(--color);
}
.label-thumbnail .v-image {
  max-width: 35%;
}
.label-thumbnail {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.label-thumbnail-text {
  padding-right: 24px;
  cursor: default !important;
}

.label-thumbnail a {
  display: flex;
  justify-content: flex-end;
  cursor: default !important;
  pointer-events: none;
}

.label-thumbnail a .v-image {
  cursor: pointer;
  pointer-events: all;
}
.label-footer {
  height: 100%;
  margin-top: auto;
  align-items: flex-end;
}

.label-footer .col {
  height: fit-content;
  min-height: 40px;
  background-color: var(--v-primary-base);
  align-content: center;
}

.label-footer a {
  color: white;
  justify-content: center;
}

.label-footer .col {
  padding: 0;
}
.footer-text {
  line-height: 1;
}
.below {
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: -24px !important;
}
.label-thumbnail .v-image {
  border: solid black 2px !important;
}
</style>
