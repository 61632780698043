import {
  GA4MeasurementProtocolItem,
  GA4EventWrapper,
  GA4PageViewEvent,
  GA4ViewItemEvent,
  GA4ViewItemListEvent,
  GA4AddToCartEvent,
  GA4RemoveFromCartEvent,
  VirtualPageURL,
  VirtualPageTypes,
  GA4CheckoutEventName,
  GA4BeginCheckoutEvent,
  GA4PurchaseEvent,
  GA4CustomEventParameters,
  GA4PromoEventName,
  GA4PromoEvent,
  GA4IncrementShopperEvent,
  GA4AddressEnteredEvent,
  GA4MeasurementProtocolEvent,
  CreditCheckEvent,
  GA4LeadCaptureSubmittedEvent,
  GA4CaptchaEvent,
  GA4CoreBuyflowStartEvent
} from '@adg/catalog/src/modules/Ecom/gtmEvents'
import { Route } from 'vue-router'
import { logger } from '@/utils/RemoteLogger'
import store from '../store'
import { GET_CATALOG } from '@/store/types'
import useCart from '@/components/order/cart/useCart'
import {
  Package,
  Catalog,
  Item,
  ItemPrice,
  Product,
  getItemPriceAsNumber,
  isPackage,
  getItemPrice,
  isEquipment,
  isUpgrade,
  isFee,
  isPromo,
  FeedItem,
  Equipment,
  Upgrade,
  ShoppingOrder,
  Promo
} from '@adg/catalog/src/modules/Catalog'
import { CreditCheckResponse } from '@adg/catalog/src/common/CreditChecks'
import type { UTMParameter } from '@adg/catalog/src/modules/Ecom/gtmEvents'
import { UrlParamValue } from '@adg/catalog/src/common/utils'

// --------------------------------------
// NOTE TO SELF: all event names and parameter names need to be in snake_case for GA4 to be happy
// --------------------------------------

let list: string | undefined = undefined
let current_page_location: string | undefined = undefined

// This is the GA4 version of impressions
const pushViewItemList = async (pkgs: Package[]) => {
  if (!pkgs || pkgs.length == 0) {
    return
  }

  const shopper = store.getters.getShopper
  const offerSet = shopper?.tags?.offerSet?.value ?? ''
  const gaMarket = shopper?.tags?.gaMarket?.value ?? ''

  const items: GA4MeasurementProtocolItem[] = []

  for (let i = 0; i < pkgs.length; i++) {
    let p = pkgs[i]

    if (p) {
      // "list" is an object level variable, not locally scoped
      // it will only change when the list of packages changes
      // which is when the view_item_list event fires so we will
      // set the global list object here from the first package
      if (i === 0) {
        const categories = getCategories(p)
        list = categories ? categories.slice(1).join('/') + '/' + offerSet : undefined
      }

      p.position = i + 1 // don't want position to be zero based
      const item: GA4MeasurementProtocolItem = buildItem(p)
      items.push(item)
    }
  }

  const eventName = 'view_item_list'
  const event: GA4ViewItemListEvent = {
    event: eventName,
    user_properties: {
      shopper_type: { value: getClassification() }
    },
    mp_event: {
      name: eventName,
      params: {
        currency: 'USD',
        items: items
      }
    }
  }

  if (offerSet != '') {
    event.user_properties.offer_set = { value: offerSet }
  }
  if (gaMarket != '') {
    event.user_properties.market = { value: gaMarket }
  }

  pushData(event)
}

const buildItem = (f: FeedItem): GA4MeasurementProtocolItem => {
  const companyName = store.getters.getUIConfig['companyName']
  const pkg = store.getters.getPackage as Package

  const item: GA4MeasurementProtocolItem = {
    item_id: f['Display Name'] ?? f.Name,
    item_name: f['Display Name'] ?? f.Name,
    item_list_name: list,
    item_brand: companyName,
    price: getPrice(f),
    item_variant: getVariant(),
    quantity: f.qty ? f.qty : 1,
    internet_speed: getInternetSpeed(f),
    internet_tier: getInternetTier(f),
    tv_tier: getTvTier(f),
    package_name: pkg && pkg['Display Name'] && pkg['Display Name'] !== '' ? pkg['Display Name'] : undefined,
    package_price: pkg && pkg['Display Name'] && pkg.Price !== '' ? getPrice(pkg) : undefined,
    index: f.position
  }

  const categories: string[] = getCategories(f)
  for (let i = 0; i < categories.length; i++) {
    item[i == 0 ? `item_category` : `item_category${i + 1}`] = categories[i]
  }

  return item
}
const buildAllItems = (order: ShoppingOrder): GA4MeasurementProtocolItem[] => {
  if (!order) {
    return []
  }

  const items: GA4MeasurementProtocolItem[] = []
  items.push(buildItem(store.getters.getPackage))

  if (order.promo) {
    items.push(buildItem(order.promo))
  }

  if (order.monthlyCharges) {
    for (let m of order.monthlyCharges) {
      items.push(buildItem(m))
    }
  }

  if (order.oneTimeCharges) {
    for (let o of order.oneTimeCharges) {
      items.push(buildItem(o))
    }
  }
  return items
}

const getVariant = (): string | undefined => {
  //in order to match ga3 we want variant to be shopper type
  return getClassification()
}

const getCategories = (item: FeedItem): string[] => {
  if (isPackage(item)) {
    return getCategoryForPackage(item)
  } else if (isEquipment(item)) {
    return getCategoryForEquipment(item)
  } else if (isUpgrade(item)) {
    return getCategoryForUpgrade(item)
  } else if (isFee(item)) {
    return ['Fee', getItemPrice(item, 'OTC') ? 'OTC/' : 'Monthly/'] // TODO: item can have both mrc and nrc
  } else if (isPromo(item)) {
    return ['Promo']
  }

  return []
}

const getCategoryForPackage = (item: Package): string[] => {
  let cat = ['Package']

  const types = item.Products.map((p) => p['Product Type'])
  types.sort()

  cat.push(getProductTypeCount([...types]) + 'P')
  cat.push(types.toString().replace(/,/g, ' '))

  return cat
}

const getProductTypeCount = (types: (string | undefined)[]): number => {
  const productsToIgnore = ['Special Offers']

  if (!types || types.length == 0) {
    return 0
  }
  for (let ignore of productsToIgnore) {
    const index = types.indexOf(ignore)
    if (index != -1) {
      types.splice(index, 1)
    }
  }

  return types.length
}

const getCategoryForEquipment = (item: Equipment): string[] => {
  const cat = ['Equipment']
  const type = item['Product Type']
  if (type) {
    cat.push(type)
  }
  return cat
}

const getCategoryForUpgrade = (item: Upgrade): string[] => {
  let cat: string[] = []
  const type = item['Product Type']

  if (item.Subcategory === 'Channel') {
    cat = ['Add-Ons', 'Channel']
  } else if (item.Subcategory === 'Equipment') {
    cat.push('Equipment')
    if (type) {
      cat.push(type)
    }
  } else if (item.Subcategory === 'Service') {
    cat = ['Add-Ons', 'Service']
    if (type) {
      cat.push(type)
    }
  } else if (item.Subcategory === 'Package Service') {
    cat = ['Add-Ons', 'Service']
  }
  return cat
}

const getClassification = (): string => {
  const shopper = store.getters.getShopper
  let type = 'Residential'

  if (
    shopper.tags?.classification !== undefined &&
    shopper.tags?.classification !== null &&
    shopper.tags?.classification !== 'null'
  ) {
    type = shopper.tags.classification
  }

  return type
}

const getPrice = (item: FeedItem) => {
  let p = getItemPrice(item, 'Monthly Price')
  if (!p) {
    p = getItemPrice(item, 'OTC')
  }
  if (!p && isPackage(item)) {
    if (item.Price) {
      p = item.Price
    }
  }
  if (typeof p === 'string') {
    const np = p.replace(/\s/g, '').replace(/^[$]?/, '') // trim and remove $
    if (np.match(/^[+-]?\d+(\.\d*)?$/)) {
      p = Number(np) // if it looks like a number ... convert to number
    }
  }
  if (typeof p !== 'number' || p === Infinity || Number.isNaN(p)) {
    logger.warn(`ga4: Invalid price calculation. p=${p} Setting value to zero`)
    p = 0 // if it's not a number, set it to zero
  }

  return Number(p.toFixed(2))
}

const getInternetSpeed = (item: FeedItem): string | undefined => {
  let speed: string | number | undefined = undefined

  try {
    if (item && isPackage(item) && item.Products) {
      for (let p of item.Products) {
        if (p.Speed) {
          speed = p.Speed
          break
        }
      }
    }
  } catch {
    logger.warn(`ga4: unable to get internet speed for ${item.Name}`)
  }

  return speed ? `${speed}` : undefined
}

const getInternetTier = (item: any): string | undefined => {
  try {
    if (!item || !isPackage(item)) {
      return undefined
    }

    const speed = getInternetSpeed(item)
    if (!speed) {
      return undefined
    }

    const cat = store.getters[GET_CATALOG] as Catalog
    const catalogSpeeds: any[] = []

    for (let pkg of cat.Packages) {
      for (let prod of pkg.Products) {
        if (prod.Speed && catalogSpeeds.indexOf(prod.Speed) === -1) {
          if (!catalogSpeeds.includes(`${prod.Speed}`)) {
            catalogSpeeds.push(`${prod.Speed}`)
          }
        }
      }
    }
    catalogSpeeds.sort(function (a, b) {
      return parseInt(a) - parseInt(b)
    })
    const internetTier = (catalogSpeeds.indexOf(speed) + 1).toString() // don't want zero based

    return internetTier
  } catch {
    logger.warn(`ecom: unable to get internet tier for ${item.Name}`)
    return undefined
  }
}

const getNumChannels = (item: any): string | undefined => {
  let channels = undefined

  if (item && item.Products) {
    for (let p of item.Products) {
      if (p['Num Channels']) {
        channels = `${p['Num Channels']}`
        break
      }
    }
  }

  return channels
}

const getTvTier = (item: any): string | undefined => {
  try {
    if (item.itemType !== 'Package') {
      return undefined
    }

    const num = getNumChannels(item)
    if (!num) {
      return undefined
    }

    const cat = store.getters[GET_CATALOG]
    const numChannels: any[] = []

    for (let pkg of cat.Packages) {
      for (let prod of pkg.Products) {
        if (prod['Num Channels'] && numChannels.indexOf(prod['Num Channels']) === -1) {
          if (!numChannels.includes(`${prod['Num Channels']}`)) {
            numChannels.push(`${prod['Num Channels']}`)
          }
        }
      }
    }
    numChannels.sort(function (a, b) {
      return parseInt(a) - parseInt(b)
    })
    const tvTier = (numChannels.indexOf(num) + 1).toString() // don't want zero based

    return tvTier
  } catch {
    logger.warn(`ecom: unable to get tv tier for ${item.Name}`)
    return undefined
  }
}

const pushPromo = async (promoEventName: GA4PromoEventName, promo: Promo, creativeSlot?: string) => {
  if (promo['Billing Codes'] && promo['Billing Codes'].length > 0) {
    const event: GA4PromoEvent = {
      event: promoEventName,
      mp_event: {
        name: promoEventName,
        params: {
          promotion_id: promo['Billing Codes'][0].Name,
          promotion_name: promo.Name,
          creative_slot: creativeSlot
          // TODO:
          // creative_name:
          // items?: // don't think we currently have item level promotions
        }
      }
    }
    pushData(event)
  }
}

const pushPackageChange = async (origPkg: Package | null | undefined, newPkg: Package | null | undefined) => {
  let package_change_type: GA4CustomEventParameters['package_change_type'] = 'initial add'
  // need to push cart remove if there is an existing
  // we no longer allow undefined/null values so now check if the origPkg is empty
  if (origPkg && origPkg.Name != '' && origPkg.Products.length > 0) {
    // determine if this is an upgrade/downgrade
    if (newPkg) {
      if (getItemPriceAsNumber(origPkg, 'Monthly Price') > getItemPriceAsNumber(newPkg, 'Monthly Price')) {
        package_change_type = 'downgrade'
      } else {
        package_change_type = 'upgrade'
      }
    }

    pushCartUpdate('remove_from_cart', [origPkg])
  }

  // push cart add
  if (newPkg) {
    pushCartUpdate('add_to_cart', [newPkg], { package_change_type: package_change_type })
  }
}

const pushMultiItemChange = async (orig: FeedItem[], newItems: FeedItem[]) => {
  let a = new Set(orig)
  let b = new Set(newItems)

  let removed = [...a].filter((x) => !b.has(x)) // a - b
  let added = [...b].filter((x) => !a.has(x)) // b - a

  if (removed.length > 0) {
    pushCartUpdate('remove_from_cart', removed)
  }
  if (added.length > 0) {
    pushCartUpdate('add_to_cart', added)
  }
}

const pushCartUpdate = (
  eventName: 'add_to_cart' | 'remove_from_cart',
  items: Item[],
  additionalEventParams: GA4CustomEventParameters = {}
) => {
  if (!items || items.length == 0) {
    return
  }

  let value: number = 0
  const ga4EcomItems: GA4MeasurementProtocolItem[] = []
  for (let item of items) {
    const i = buildItem(item)
    value += (i.price ?? 0) - (i.discount ?? 0)
    ga4EcomItems.push(i)
  }

  const event: GA4AddToCartEvent | GA4RemoveFromCartEvent = {
    event: eventName,
    mp_event: {
      name: eventName,
      params: {
        currency: 'USD',
        value: value,
        items: ga4EcomItems,
        ...additionalEventParams
      }
    }
  }

  pushData(event)
}

const pushLeadCaptureSubmitted = (leadFormName: string) => {
  const event: GA4LeadCaptureSubmittedEvent = {
    event: 'lead_capture_submitted',
    mp_event: {
      name: 'lead_capture_submitted',
      params: {
        lead_capture_form_name: leadFormName
      }
    }
  }
  pushData(event)
}

const pushCreditCheck = async (ccr: CreditCheckResponse) => {
  const event: CreditCheckEvent = {
    event: 'credit_check',
    mp_event: {
      name: 'credit_check',
      params: {
        credit_bureau: ccr.creditBureauName,
        credit_check_type: ccr.creditCheckType,
        credit_check_result: ccr.creditCheckResult
      }
    }
  }
  pushData(event)
}

const pushCheckout = async (eventName: GA4CheckoutEventName) => {
  const { shoppingCart } = useCart(store)
  const { grandTotal } = useCart(store)

  const event: GA4BeginCheckoutEvent = {
    event: eventName,
    mp_event: {
      name: eventName,
      params: {
        currency: 'USD',
        value: parseFloat(grandTotal.value.toFixed(2)),
        items: buildAllItems(shoppingCart.value)
      }
    }
  }

  pushData(event)
}

const pushPurchase = async () => {
  const { shoppingCart } = useCart(store)
  const { grandTotal } = useCart(store)
  const order: ShoppingOrder = shoppingCart.value
  const eventName = 'purchase'

  const event: GA4PurchaseEvent = {
    event: eventName,
    mp_event: {
      name: eventName,
      params: {
        currency: 'USD',
        transaction_id: `${order.id}`,
        value: parseFloat(grandTotal.value.toFixed(2)),
        items: buildAllItems(order)
      }
    }
  }

  pushData(event)
}

const pushShopperMetric = async () => {
  const eventName = 'increment_shopper_count'
  const event: GA4IncrementShopperEvent = {
    event: eventName,
    mp_event: {
      name: eventName,
      params: {
        shopper_count: 1
      }
    }
  }

  pushData(event)
}

const pushCaptchaEvent = async (score: number, timeElapsed: number) => {
  const eventName = 'captcha'
  const event: GA4CaptchaEvent = {
    event: eventName,
    mp_event: {
      name: eventName,
      params: {
        captcha_score: score,
        time_elapsed: timeElapsed
      }
    }
  }
  pushData(event)
}

const pushAddressEntered = async (inputAddress: string) => {
  const eventName = 'address_entered'
  const event: GA4AddressEnteredEvent = {
    event: eventName,
    mp_event: {
      name: eventName,
      params: {
        input_address: inputAddress
      }
    }
  }
  pushData(event)
}

const pushRoute = async (vueTo: Route, isInitialPreSale: boolean = false, isCommercial: boolean = false) => {
  const translatedPath = translatePath(vueTo, isInitialPreSale, isCommercial)
  if (translatedPath) return pushPageView(translatedPath)
}

const pushPageView = (path: VirtualPageURL) => {
  if (path != undefined) {
    const eventName = 'page_view'
    const event: GA4PageViewEvent = {
      event: eventName,
      mp_event: {
        name: eventName,
        params: {
          page_location: path,
          page_title: window.document.title
        }
      }
    }
    if (VirtualPageTypes[path] === undefined) {
      logger.warn(`pushPageView(): virtualPageType not found for ${path}`)
    }
    current_page_location = path
    pushData(event)
  }
}

const pushCoreBuyflowStart = async () => {
  const urlParams = new URLSearchParams(window.location.search)
  const eventName = 'core_buyflow_start'

  const event: GA4CoreBuyflowStartEvent = {
    event: eventName,
    mp_event: {
      name: eventName,
      params: {
        utm_campaign: urlParams.get('utm_campaign'),
        utm_source: urlParams.get('utm_source'),
        utm_medium: urlParams.get('utm_medium'),
        utm_content: urlParams.get('utm_content'),
        utm_term: urlParams.get('utm_term')
      }
    }
  }

  await pushData(event)
}

const getFirstPagePath = (): VirtualPageURL => {
  if (window.location.search) {
    const params = new URLSearchParams(window.location.search)
    // Only want to fire `/addressToolbar` when going through the Ecomm channel.
    // If `utm_source` exists, assume channel is NOT Ecomm.
    if (params.has('address') && !params.has('utm_source')) {
      return '/addressToolbar'
    }
  }
  return '/checkAddress'
}

const translatePath = (vueTo: Route, isInitialPreSale: boolean, isCommercial: boolean): VirtualPageURL | undefined => {
  let translatedPath: VirtualPageURL | undefined = undefined

  if (vueTo && vueTo.path && vueTo.path !== '/') {
    if (vueTo.path.indexOf('/order/1') != -1) {
      translatedPath = getFirstPagePath()
    } else if (vueTo.path.indexOf('/order/2') != -1) {
      translatedPath = '/chooseServices'
    } else if (vueTo.path.indexOf('/order/3') != -1) {
      translatedPath = '/customizeOptions'
    } else if (vueTo.path.indexOf('/order/4') != -1) {
      translatedPath = '/setupAccount'
    } else if (vueTo.path.indexOf('/order/5') != -1) {
      translatedPath = '/scheduleInstallation'
    } else if (vueTo.path.indexOf('/confirm') != -1) {
      translatedPath = isInitialPreSale ? '/presaleConfirmation' : '/orderConfirmation'
    } else if (vueTo.path.indexOf('/activeAccount') != -1) {
      if (isCommercial) {
        translatedPath = '/commercialQuestion'
      } else {
        translatedPath = '/activeAccount'
      }
    } else if (vueTo.path.indexOf('/noservice') != -1) {
      translatedPath = '/noservice'
    } else if (vueTo.path.indexOf('/comingSoon') != -1) {
      translatedPath = '/comingSoon'
    } else if (vueTo.path.indexOf('/leadCapture') != -1) {
      translatedPath = '/leadCapture'
    } else if (vueTo.path.indexOf('/error') != -1) {
      translatedPath = '/error'
    } else {
      // need to analyze why we don't have a match so log it server side
      logger.warn(`Unknown GA mapping for path=${vueTo.path}`)
    }
  }

  return translatedPath
}

const setDataLayerVariable = (data: { event: string; [key: string]: string }) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(data)
}

const pushData = async (data: GA4EventWrapper) => {
  const { grandTotal, monthlyTotal } = useCart(store)

  // our own data
  data.page = window.location.href
  data.ts = Date.now()
  data.page_location = current_page_location
  data.page_title = window.document.title
  data.session_id = store.getters.getShopper?.googleSessionId
  data.session_number = store.getters.getShopper?.googleSessionNumber
  data.host = window.location.host
  data.app_load_id = store.getters.getAppLoadId
  data.buyflow_start_id = store.getters.getBuyflowStartId

  if (data.event === 'purchase') {
    data.orderTotal = parseFloat(grandTotal.value.toFixed(2))
    data.orderMonthlyTotal = parseFloat(monthlyTotal.value.toFixed(2))
  }

  if (!data.user_properties) {
    data.user_properties = {}
  }
  data.user_properties.gcid = { value: store.getters.getShopper?.googleClientId }

  // edge case for first page when google client id hasn't populated yet but we still want to capture page view
  if (
    (data.user_properties.gcid === undefined ||
      data.user_properties.gcid.value == undefined ||
      data.user_properties.gcid.value == '') &&
    data.event === 'page_view' &&
    (data.mp_event.params.page_location === '/checkAddress' || data.mp_event.params.page_location === '/addressToolbar')
  ) {
    data.user_properties.gcid = { value: 'unknown' }
  }

  await store.dispatch('addGa4Event', data)

  // The following code puts the event into datalayer for tag manager
  window.dataLayer = window.dataLayer || []
  // clear measurement protocol data out of datalayer so it doesn't get pushed on next event
  window.dataLayer.push({ mp_event: null })
  // put our event into the datalayer
  window.dataLayer.push(data)
}

export default {
  translatePath,
  pushRoute,
  pushCoreBuyflowStart,
  pushPageView,
  pushViewItemList,
  pushMultiItemChange,
  pushPackageChange,
  pushCheckout,
  pushPurchase,
  pushPromo,
  pushShopperMetric,
  pushCaptchaEvent,
  pushAddressEntered,
  pushCreditCheck,
  setDataLayerVariable,
  pushLeadCaptureSubmitted,
  getClassification
}
