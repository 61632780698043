import { AccountField, CustomRule } from '@adg/catalog/src/common/UIConfig'
import { IShopper } from '@adg/catalog/src/modules/Shopper'
import { computed } from '@vue/composition-api'
import { getConfigItem } from '@/components/shared/getConfigItem'
import { assertNever, checkConditional, dateDiff } from '@adg/catalog/src/common/utils'
import { ConfigKeys } from '@adg/catalog/src/modules/Catalog'
import { logger } from '@/utils/RemoteLogger'

export const validAccountField = (field: AccountField, shopper: IShopper) => {
  if (field.conditionals) {
    return checkConditional(field.conditionals, shopper)
  }
  switch (field.type) {
    case 'text':
    case 'textField':
    case 'textArea':
    case 'checkbox':
    case 'addressSeniority':
    case 'differentBilling':
    case 'image':
    case 'date':
    case 'terms':
    case 'questions':
    case 'schedule':
    case 'referral':
    case 'dropdown':
    case 'dropdownWithOther':
    case 'radioGroup':
      return true
    default:
      return assertNever(field.type, `Unknown account field type: ${field.type}`)
  }
}

export const requiredRule = (value: string) => !!value || 'Required'

const ageLimit = computed(() => getConfigItem(ConfigKeys.ageLimit) ?? 0)

const customRules = computed(() => getConfigItem(ConfigKeys.customRules))

const emailRule = (value: string) => {
  const emailPattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return emailPattern.test(value) || 'Invalid email'
}

const phoneRule = (value: string) => {
  const phonePattern = /^\(?[2-9]\d{2}\)?[\s.-]\d{3}[\s.-]\d{4}$/
  return phonePattern.test(value?.trimEnd() ?? '') || 'Please enter full phone number with area code'
}

// todo: figure out how to type correctly
const dobRule = (value) => {
  if (value && !value.match(/^\d\d\/\d\d\/\d\d\d\d$/)) {
    return 'MM/DD/YYYY format required'
  }
  if (value) {
    return dateDiff(value).years >= Number(ageLimit.value) ? true : `Must be ${ageLimit.value} years or older`
  } else {
    return true
  }
}

const ssnRule = (value: string) => {
  return value?.length === 4
}

const pinRule = (value: string) => {
  return value?.length === 4
}

export const ruleMap = computed(() => {
  const rules = new Map<string, Function>()

  rules.set('required', requiredRule)
  rules.set('email', emailRule)
  rules.set('phone', phoneRule)
  rules.set('ssn', ssnRule)
  rules.set('dob', dobRule)
  rules.set('pin', pinRule)

  customRules.value?.forEach((rule: CustomRule) => {
    try {
      const match = rule.regex.match(/^\/(.*)\/([gmiu]*)$/)
      if (match === null || match[1] === null) throw new Error(`Invalid RE "${rule.regex}"`)
      const regex = new RegExp(match[1], match[2])
      const newFunc = (value: string): string | boolean => regex.test(value) === !rule.invalidate || rule.errorMsg
      rules.set(rule.name, newFunc)
    } catch (err) {
      logger.error(`Error creating custom rule function: ${err}`)
    }
  })

  return rules
})

// const internationalPhone = computed(() => $store.getters.getShopper.customInfo?.internationalPhone ?? false)

// todo: figure out how to type store
export const getRules = (store, ruleNames: string[]): Function[] => {
  const rulesArray: Function[] = []
  if (ruleNames) {
    for (let r of ruleNames) {
      // inline rule
      if (r.indexOf('return ') != -1) {
        rulesArray.push(new Function('value', r))
      }
      let ruleVal: ReturnType<typeof ruleMap.value.get>
      if (ruleMap.value && (ruleVal = ruleMap.value.get(r))) {
        if (!(r === 'phone' && store.getters.getShopper.customInfo?.internationalPhone)) {
          rulesArray.push(ruleVal)
        }
      }
    }
  }
  return rulesArray
}
