<template>
  <span v-if="validAccountField(field, shopper)">
    <div id="account-field-hint" class="account-field-hint" v-if="field.hint" v-html="field.hint"></div>
    <v-text-field
      v-if="field.type === 'textField'"
      v-model="accountFieldModel"
      :error="!accountFieldModel && field.showInitialError"
      :label="field.label"
      :rules="rules"
      :maxlength="field.maxLength ? field.maxLength : Infinity"
      v-mask="mask"
      v-bind="textFieldProps"
    >
      <template v-if="field.label || field.labelMacro" v-slot:label>
        <span
          @click.stop
          class="text-box-label"
          v-html="field.labelMacro ? uiMacroParser(shopper, field.labelMacro, true) : field.label"
        ></span>
      </template>
    </v-text-field>

    <v-radio-group
      :rules="rules"
      v-if="field.type === 'radioGroup' && field.options"
      v-model="accountFieldModel"
      :label="field.label"
    >
      <div v-for="(option, index) in field.options" :key="index">
        <v-radio :label="option.label" :value="option.value">
          <template v-if="option.labelMacro" v-slot:label>
            <span @click.stop class="radio-option-label" v-html="uiMacroParser(shopper, option.labelMacro, true)"></span>
          </template>
        </v-radio>
      </div>
      <template v-if="field.labelMacro" v-slot:label>
        <span @click.stop class="radio-group-label" v-html="uiMacroParser(shopper, field.labelMacro, true)"></span>
      </template>
    </v-radio-group>

    <v-text-field
      v-else-if="field.type === 'date'"
      v-model="dobFieldModel"
      :label="field.label"
      :rules="rules"
      :maxlength="field.maxLength ? field.maxLength : Infinity"
      v-mask="mask"
      v-bind="textFieldProps"
      autocomplete="bday"
    >
      <template v-if="field.labelMacro" v-slot:label>
        <span @click.stop class="date-textfield-label" v-html="uiMacroParser(shopper, field.labelMacro, true)"></span>
      </template>
    </v-text-field>

    <!-- i know onkeypress is ugly; v-mask does not work on v-textarea - this will block characters needed for html injection from being input -->
    <v-textarea
      v-else-if="field.type === 'textArea'"
      :label="field.label"
      v-model="accountFieldModel"
      :rules="rules"
      onkeypress="return /[^<>&]/i.test(event.key)"
      solo
      no-resize
      :maxlength="field.maxLength ? field.maxLength : Infinity"
      v-bind="textFieldProps"
    >
      <template v-if="field.labelMacro" v-slot:label>
        <span @click.stop class="textarea-field-label" v-html="uiMacroParser(shopper, field.labelMacro, true)"></span>
      </template>
    </v-textarea>

    <v-checkbox
      v-else-if="field.type === 'checkbox'"
      v-model="accountFieldBoolean"
      :label="field.label"
      dense
      class="noMargin"
      style="padding-top: 0px; padding-bottom: 0px"
      :rules="rules"
    >
      <template v-if="field.labelMacro" v-slot:label>
        <span @click.stop class="checkbox-field-label" v-html="uiMacroParser(shopper, field.labelMacro, true)"></span>
      </template>
    </v-checkbox>

    <v-select
      v-if="field.type === 'dropdown'"
      :items="field.dropdownOptions"
      v-model="accountFieldModel"
      :label="field.label"
      outlined
      :background-color="field.backgroundColor ? field.backgroundColor : 'white'"
      :rules="rules"
      v-bind="textFieldProps"
    >
      <template v-if="field.labelMacro" v-slot:label>
        <span @click.stop class="dropdown-label" v-html="uiMacroParser(shopper, field.labelMacro, true)"></span>
      </template>
    </v-select>

    <PhoneCarrierDropdown v-else-if="field.type === 'dropdownWithOther'" :field="field" />

    <v-list-item v-if="field.type === 'terms'" class="pl-0 pb-3">
      <template v-slot:default="{ active, toggle }">
        <v-list-item-action style="align-self: start">
          <v-checkbox v-model="termsModel" :rules="rules" class="blackCheckbox" />
        </v-list-item-action>
        <v-list-item-content class="pl-0">
          <!-- i know a span in a span is ugly, but otherwise the tooltip drops to the line below -->
          <span>
            <span
              @click.stop
              style="color: rgba(0, 0, 0, 0.6)"
              v-html="field.labelMacro ? uiMacroParser(shopper, field.labelMacro, true) : field.label"
            ></span>
          </span>
          <span id="terms-checkbox-required" v-if="!termsModel" class="pl-2" style="color: red; font-size: 12px"> Required </span>
        </v-list-item-content>
      </template>
    </v-list-item>
    <span
      v-else-if="field.type === 'text'"
      v-html="field.labelMacro ? uiMacroParser(shopper, field.labelMacro, true) : field.label"
      id="custom-text"
    ></span>
    <div v-else-if="field.type === 'addressSeniority'">
      <AddressSeniority
        :getAddress="GET_PREVIOUS_ADDRESS_SUGGESTIONS"
        :fetchAddress="FETCH_PREVIOUS_ADDRESS_SUGGESTIONS"
        setAddress="updateShopperPreviousAddress"
        :checkboxLabel="addressSeniorityLabel"
        defaultCheckedState
        :rules="rules"
      />
    </div>
    <div v-else-if="field.type === 'differentBilling'" class="pb-0">
      <AddressSeniority
        :getAddress="GET_BILLING_ADDRESS_SUGGESTIONS"
        :fetchAddress="FETCH_BILLING_ADDRESS_SUGGESTIONS"
        setAddress="updateShopperBillingAddress"
        addressLabel="Enter your billing address"
        :checkboxLabel="differentBillingLabel"
        showAddressOnCheck
        :rules="rules"
      />
    </div>
    <div v-else-if="field.type === 'image'">
      <span v-html="field.labelMacro ? uiMacroParser(shopper, field.labelMacro, true) : field.label"></span>
      <image-uploader
        :capture="true"
        :debug="0"
        :maxWidth="500"
        :quality="1.0"
        :scaleRatio="1.0"
        :autoRotate="true"
        outputFormat="jpg"
        :preview="true"
        accept="image/*"
        @input="onFileSelected"
        :rules="rules"
      ></image-uploader>
    </div>
    <div v-else-if="field.type === 'questions'">
      <SecurityQuestions :field="field" :rules="rules" />
    </div>
    <div v-else-if="field.type === 'referral'">
      <Referrals :field="field" :rules="rules" />
    </div>
  </span>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from '@vue/composition-api'
import $store from '@/store'
import { usePiniaShopper } from '@/store/pinia/piniaShopper'
import AddressSeniority from '@/components/shared/AddressSeniority.vue'
import { AccountField, defaultMaskMap } from '@adg/catalog/src/common/UIConfig'
import { getConfigItem } from '../../shared/getConfigItem'
import { ConfigKeys } from '@adg/catalog/src/modules/Catalog'
import { uiMacroParser } from '@/utils/ShopperHelpers'
//todo: check if this is pnpm related
import ImageUploader from 'vue-image-upload-resize'
import {
  GET_PREVIOUS_ADDRESS_SUGGESTIONS,
  FETCH_PREVIOUS_ADDRESS_SUGGESTIONS,
  FETCH_BILLING_ADDRESS_SUGGESTIONS,
  GET_BILLING_ADDRESS_SUGGESTIONS
} from '@/store/types'
import { IShopper } from '@adg/catalog/src/modules/Shopper'
import { getRules, requiredRule, validAccountField } from './accountFunctions'
import SecurityQuestions from './SecurityQuestions.vue'
import Referrals from './Referrals.vue'
import { getNum } from '@adg/catalog/src/common/utils'
import PhoneCarrierDropdown from '../PhoneCarrierDropdown.vue'

export default defineComponent({
  name: 'AccountFieldUI',
  components: {
    AddressSeniority,
    ImageUploader,
    SecurityQuestions,
    Referrals,
    PhoneCarrierDropdown
  },
  props: {
    field: {
      type: Object as PropType<AccountField>,
      required: true
    },
    maskMap: Map
  },
  setup(props, { refs, emit }) {
    const internationalPhone = computed(() => $store.getters.getShopper.customInfo?.internationalPhone ?? false)
    const shopper = computed((): IShopper => $store.getters.getShopper)
    const rules = getRules($store, props.field.rules ?? [])

    // need to support both customInfo fields as well as regular account fields
    const accountFieldModel = computed({
      get: () => {
        let a = $store.getters.getAccountField(props.field.storeProp) ?? props.field.defaultValue
        return a
      },
      set: (value: any) => {
        $store.commit('setAccountField', { field: props.field.storeProp, value })
        // TODO: see if this is correct for pinia
        if (props.field.storeProp) {
          usePiniaShopper().setShopperField(props.field.storeProp, value)
        }
        emit('change', value)
      }
    })

    const accountFieldBoolean = computed({
      get: () => {
        if ($store.getters.getAccountField(props.field.storeProp) === undefined) {
          return props.field.defaultValue ?? false
        }
        return $store.getters.getAccountField(props.field.storeProp)
      },
      set: (value: boolean) => {
        $store.commit('setAccountField', { field: props.field.storeProp, value })
        emit('change', value)
      }
    })

    const termsModel = computed({
      get: () => $store.getters.getShopper.customInfo?.termsConsent?.termsAndConditionsConsent ?? false,
      set: (termsAndConditionsConsent) => {
        const termsAndConditionsConsentTimestamp = new Date().toISOString()
        $store.commit('setTermsCheckbox', { termsAndConditionsConsent, termsAndConditionsConsentTimestamp })
        usePiniaShopper().shopper.customInfo.termsConsent = { termsAndConditionsConsent, termsAndConditionsConsentTimestamp }
        emit('change', termsAndConditionsConsent)
      }
    })

    const dobFieldModel = computed({
      get: () => $store.getters.getAccountField(`${props.field.storeProp}String`),
      set: (value) => {
        if (value && value.match(/^\d\d\/\d\d\/\d\d\d\d$/)) {
          // $store.commit('setDob', dob)
          const dateString = new Date(value).toISOString()
          $store.commit('setAccountField', { field: props.field.storeProp, value: dateString })
          $store.commit('setAccountField', { field: `${props.field.storeProp}String`, value })
          // TODO: see if this is correct for pinia
          if (props.field.storeProp) {
            usePiniaShopper().setDateString(props.field.storeProp, value, dateString)
          }
          emit('change', value)
        }
      }
    })

    //setup rules
    // const rules = computed((): Function[] => {
    //   const rulesArray: Function[] = []
    //   if (props?.field?.rules) {
    //     const ruleMap = props.ruleMap as Map<string, Function>
    //     for (let r of props.field.rules) {
    //       let ruleVal: ReturnType<typeof ruleMap.get>
    //       if (ruleMap && (ruleVal = ruleMap.get(r))) {
    //         if (!(r === 'phone' && internationalPhone.value)) {
    //           rulesArray.push(ruleVal)
    //         }
    //       }
    //     }
    //   }
    //   return rulesArray
    // })

    const mask = computed(() => {
      if (props?.field?.mask) {
        let maskMapLocal = props?.maskMap as Map<string, any>
        if (!maskMapLocal) {
          maskMapLocal = defaultMaskMap as Map<string, any>
        }
        let maskVal: ReturnType<typeof maskMapLocal.get>
        if (internationalPhone.value && props.field.mask === 'phone') {
          return maskMapLocal.get('intPhone')
        }
        return maskMapLocal.get(props.field.mask)
      }

      return {
        mask: '*'.repeat(props.field?.maxLength ? getNum(props.field.maxLength) : 500),
        tokens: { '*': { pattern: /[^<>&]/ } }
      }
    })

    const textFieldProps = computed(() => {
      const textProps: Record<string, string | boolean> = {}
      if (props.field.fieldProps) {
        for (let prop in props.field.fieldProps) {
          textProps[prop] = props.field.fieldProps[prop]
        }
      } else {
        textProps[getConfigItem(ConfigKeys.textFieldStyle) ?? 'solo'] = true
        textProps.singleLine = true
      }
      return textProps
    })

    // const idImage = computed({
    //   get: () => $store.getters.getIdImage,
    //   set: (val) => $store.commit(SET_ID_IMAGE, val)
    // })

    // todo: figure out how to type this correctly
    const onFileSelected = (event) => {
      accountFieldModel.value = event
    }

    const formatPhoneNumber = (phoneNumberString: string) => {
      // replace all non-digits with empty string
      var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
      // check if first digit is not equal to 1, then format string to match mask
      if (cleaned[0] !== '1') {
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
          return '(' + match[1] + ') ' + match[2] + '-' + match[3]
        }
      }
      return null
    }

    const getShopperElement = ({ src, element }) => {
      let urlArg
      try {
        const shp = shopper.value
        if (src === 'SHOPPER') {
          urlArg = getDescendantProp(shp, `${element}`) ?? ''
        }
      } catch (e) {
        urlArg = e
          .toString()
          .replace(/^.*(?=Cannot)/, '')
          .replace(' of undefined', '')
      }
      return urlArg
      function getDescendantProp(obj, desc) {
        var arr = desc.split('.')
        while (arr.length > 0) {
          let hasQuestionMark = false
          if (arr[0].match(/\?$/)) {
            arr[0] = arr[0].replace(/\?$/, '')
            hasQuestionMark = true
          }
          const elem = arr.shift()
          obj = obj[elem]
          if (obj === undefined) {
            if (hasQuestionMark) {
              return ''
            } else {
              return null
            }
          }
        }
        return obj
      }
    }

    const differentBillingLabel = props.field.label ?? 'My billing address is different than the address above'
    const addressSeniorityLabel = props.field.label ?? `I've lived at the above address for 1 year or more`

    return {
      accountFieldModel,
      rules,
      mask,
      usePiniaShopper,
      termsModel,
      requiredRule,
      GET_PREVIOUS_ADDRESS_SUGGESTIONS,
      FETCH_PREVIOUS_ADDRESS_SUGGESTIONS,
      FETCH_BILLING_ADDRESS_SUGGESTIONS,
      GET_BILLING_ADDRESS_SUGGESTIONS,
      textFieldProps,
      dobFieldModel,
      validAccountField,
      onFileSelected,
      shopper,
      differentBillingLabel,
      addressSeniorityLabel,
      accountFieldBoolean,
      getShopperElement,
      formatPhoneNumber,
      uiMacroParser
    }
  },
  mounted() {
    if (this.field.type === 'checkbox') {
      if (typeof this.field?.defaultValue !== 'boolean') {
        $store.commit('setAccountField', { field: this.field.storeProp, value: false })
      } else {
        $store.commit('setAccountField', { field: this.field.storeProp, value: this.field?.defaultValue ?? false })
      }
    } else if (this.field?.configureDefault && this.field?.src && this.field?.element) {
      if (this.field.storeProp === 'phone') {
        const defaultValue = this.formatPhoneNumber(this.getShopperElement({ src: this.field.src, element: this.field.element }))
        $store.commit('setAccountField', { field: this.field.storeProp, value: defaultValue ?? '' })
      } else {
        const fieldDefaultValue = this.getShopperElement({
          src: this.field.src,
          element: this.field.element
        })
        $store.commit('setAccountField', { field: this.field.storeProp, value: fieldDefaultValue ?? '' })
      }
    }
  }
})
</script>

<style>
.opaque-text .v-label {
  color: rgb(0, 0, 0);
  font-size: 12px;
}
.opaque-text .v-text-field > .v-input__control > .v-input__slot:before {
  border-color: rgba(0, 0, 0);
}
.gothic-font .v-input input {
  font-family: 'Century Gothic', 'Segoe UI', Arial !important;
  font-size: 16px;
}
</style>
